import React, { useEffect, useState } from "react";
import { Button, Drawer, Form, Input, message, Select, Space } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import axiosConfig from "../../../util/axiosConfig";
import { MaterialsType } from "../../../shared/interfaces/material.interface";
import { TruckCategoryProfile } from "../../../shared/interfaces/category.interface";
import {
  MappedZone,
  PricingPerTruckProfile,
  ZonesType,
} from "../../../shared/interfaces/zone.interface";
import requestErrorHandler from "../../../util/requestErrorHandler";

const MapZone = ({
  pickUpZone,
  dropOffZone,
  material,
  truckProfiles,
  getMaterial,
}: {
  pickUpZone: ZonesType;
  dropOffZone: ZonesType;
  material: MaterialsType;
  truckProfiles: TruckCategoryProfile[];
  getMaterial: any;
}) => {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [mappedZone, setMappedZone] = useState<MappedZone>({
    id: "",
    pickUpZoneId: "",
    deliveryZoneId: "",
    pricingPerTruckProfile: [],
    materialId: "",
  });
  const [pricePerTruck, setPricePerTruck] = useState<PricingPerTruckProfile[]>(
    []
  );

  async function handleSubmit() {
    const data = {
      pickUpZoneId: pickUpZone.id,
      deliveryZoneId: dropOffZone.id,
      pricingPerTruckProfile: form.getFieldsValue().truckProfilesPrice,
      materialId: material.id,
    };

    setIsLoading(true);
    await axiosConfig
      .post("zones/map-zone", data)
      .then((response: any) => {
        message.success("Zone mapped successfully");
        form.resetFields();
        setOpen(false);
        getMaterial();
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  async function handleUpdate() {
    setIsLoading(true);
    await axiosConfig
      .put(`/zones/update-mapped-zones?mappedZoneId=${mappedZone.id}`, {
        pricingPerTruckProfile: form.getFieldsValue().truckProfilesPrice,
      })
      .then((response: any) => {
        message.success("Mapped zone updated successfully");
        setOpen(false);
        getMaterial();
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  async function handleDelete() {
    setIsLoading(true);
    await axiosConfig
      .delete(`/zones/delete-mapped-zones?mappedZoneId=${mappedZone.id}`)
      .then((response: any) => {
        message.success("Mapped zone deleted successfully");
        setOpen(false);
        getMaterial();
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    if (material.mappedZones.length > 0) {
      const mappedZones = material.mappedZones;
      const data = mappedZones.filter((mz) => {
        return (
          mz.pickUpZoneId === pickUpZone.id &&
          mz.deliveryZoneId === dropOffZone.id
        );
      });
      if (data.length > 0) {
        const mpData = data[0];
        setMappedZone(mpData);
        setPricePerTruck(mpData.pricingPerTruckProfile);
      }
    }
  }, [pickUpZone, dropOffZone, material, truckProfiles]);

  return (
    <>
      <div
        className="table-cell border border-gray-300 border-b-gray-300 border-solid"
        key={dropOffZone.id}
      >
        <div
          className="w-full h-full p-2 cursor-pointer"
          onClick={() => setOpen(true)}
        >
          {mappedZone.pricingPerTruckProfile?.length > 0 ? (
            mappedZone.pricingPerTruckProfile.map((pricingPerTruckProfile) => (
              <div key={pricingPerTruckProfile.truckProfileId}>
                {truckProfiles.map((truckProfile, idx) =>
                  truckProfile.id === pricingPerTruckProfile.truckProfileId ? (
                    <div key={idx} className="p-1">
                      {truckProfile.type} :{" "}
                      {Number(pricingPerTruckProfile.price).toLocaleString(
                        "en-NG",
                        { style: "currency", currency: "NGN" }
                      )}{" "}
                    </div>
                  ) : (
                    ""
                  )
                )}
              </div>
            ))
          ) : (
            <div>&nbsp;</div>
          )}
        </div>
      </div>
      <Drawer
        title={
          "Map " + pickUpZone.name + " zone to " + dropOffZone.name + " zone"
        }
        placement="right"
        onClose={() => setOpen(false)}
        open={open}
      >
        <Form
          initialValues={{ truckProfilesPrice: pricePerTruck }}
          className="w-100"
          onFinish={mappedZone.id ? handleUpdate : handleSubmit}
          form={form}
          layout="vertical"
        >
          <Form.List name="truckProfilesPrice">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field) => (
                  <Space key={field.key} align="baseline">
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, curValues) =>
                        prevValues.price !== curValues.price ||
                        prevValues.truckProfileId !== curValues.truckProfileId
                      }
                    >
                      {() => (
                        <Form.Item
                          {...field}
                          label="Truck profile"
                          name={[field.name, "truckProfileId"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing truck profile",
                            },
                          ]}
                        >
                          <Select
                            style={{ width: 130 }}
                            placeholder="Select truck profile"
                          >
                            {truckProfiles.map((item) => (
                              <Select.Option key={item.id} value={item.id}>
                                {item.type}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      )}
                    </Form.Item>
                    <Form.Item
                      {...field}
                      label="Price"
                      name={[field.name, "price"]}
                      rules={[{ required: true, message: "Missing price" }]}
                    >
                      <Input />
                    </Form.Item>

                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                  </Space>
                ))}

                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add profiles
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Button
            type="primary"
            htmlType="submit"
            block
            size="large"
            loading={isLoading}
            className="bg-[#096DD9]"
          >
            Save
          </Button>
        </Form>
        <Button
          danger
          type="primary"
          block
          size="large"
          loading={isLoading}
          className="mt-4"
          onClick={handleDelete}
        >
          Delete
        </Button>
      </Drawer>
    </>
  );
};

export default MapZone;
