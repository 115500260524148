import { RouterProvider } from "react-router-dom";
import Spinner from "./components/Spinner";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
import { Buffer } from "buffer";
import Router from "./Router";
import store from "./store";
import { APIProvider } from "@vis.gl/react-google-maps";
import useLoadScript from "./hooks/useLoadScript";

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;

window.Buffer = Buffer;

function App() {
  const scriptLoaded = useLoadScript();

  // if (scriptLoaded) {
  //   return <></>;
  // }
  return (
    <Provider store={store}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#096DD9",
          },
        }}
      >
        <APIProvider apiKey={GOOGLE_API_KEY!}>
          <RouterProvider router={Router} fallbackElement={<Spinner />} />
        </APIProvider>
      </ConfigProvider>
    </Provider>
  );
}

export default App;
