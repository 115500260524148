import React, { useState } from "react";
import { Button, Drawer, Form, Input, message } from "antd";
import axiosConfig from "../../../util/axiosConfig";
import requestErrorHandler from "../../../util/requestErrorHandler";

const RenteePasswordChange = ({ rentee, getRentees }: any) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  async function handleSubmit() {
    setIsLoading(true);
    await axiosConfig
      .put(`/truck-owners/admin-change-password`, {
        ...form.getFieldsValue(),
        truckOwnerId: rentee.id,
      })
      .then((response: any) => {
        message.success("Admin updated successfully");
        form.resetFields();
        getRentees();
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setOpen(false);
        setIsLoading(false);
      });
  }

  return (
    <>
      <span className="cursor-pointer" onClick={() => setOpen(true)}>
        Change password
      </span>
      <Drawer
        title="Change Password"
        placement="right"
        onClose={() => setOpen(false)}
        open={open}
      >
        <Form
          initialValues={rentee}
          className="w-100"
          onFinish={handleSubmit}
          form={form}
          layout="vertical"
        >
          <Form.Item
            name="newPassword"
            label="New password"
            rules={[{ required: true, message: "Enter a valid password" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="adminEmail"
            label="Email"
            rules={[
              { required: true, message: "Enter a valid email" },
              { type: "email" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="contactNumber"
            label="Contact"
            rules={[{ required: true, message: "Enter a valid contact" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="adminFirstName"
            label="First name"
            rules={[{ required: true, message: "Enter a valid name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="adminLastName"
            label="Last name"
            rules={[{ required: true, message: "Enter a valid name" }]}
          >
            <Input />
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            block
            size="large"
            loading={isLoading}
            className="bg-[#096DD9]"
          >
            Save
          </Button>
        </Form>
      </Drawer>
    </>
  );
};

export default RenteePasswordChange;
