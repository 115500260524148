import { OrdersType } from "../shared/interfaces/requests.interface";
import axiosConfig from "../util/axiosConfig";
import requestErrorHandler from "../util/requestErrorHandler";

export const getAllRequest = async (paymentStatus: boolean) => {
  let orderRequests: OrdersType[] = [];

  try {
    const response = await axiosConfig.get(
      `order/all-order-request?paymentStatus=${paymentStatus}`
    );
    const { data } = response;
    orderRequests = data.orderRequests?.sort(
      (reqFirst: OrdersType, reqSec: OrdersType) =>
        new Date(reqSec.createdAt).getTime() -
        new Date(reqFirst.createdAt).getTime()
    );
  } catch (error) {
    requestErrorHandler(error);
  }

  return orderRequests;
};
