import React, { useEffect, useState } from "react";
import {
  Avatar,
  Breadcrumb,
  Button,
  message,
  Modal,
  Popconfirm,
  Skeleton,
  Switch,
} from "antd";
import axiosConfig from "../../../util/axiosConfig";
import {
  DefaultTrucks,
  TrucksType,
} from "../../../shared/interfaces/trucks.interface";
import { Link, useNavigate, useParams } from "react-router-dom";
import { DeleteOutlined } from "@ant-design/icons";
import avatar from "../../../assets/avatar.png";
import { useCallback } from "react";
import EditTruck from "./EditTruck";
import requestErrorHandler from "../../../util/requestErrorHandler";
import AddDevice from "../../../components/AddDevice";

const ShowTruck = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showDriverModal, setShowDriverModal] = useState(false);
  const { truckId } = useParams();
  const [showDeviceModal, setShowDeviceModal] = useState<boolean>(false);
  const [truck, setTruck] = useState<TrucksType>({ ...DefaultTrucks });

  const getTruck = useCallback(async () => {
    setIsLoading(true);
    await axiosConfig
      .get("customer-trucks/get-one?truckId=" + truckId)
      .then((response: any) => {
        setTruck(response.data.truck);
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [truckId]);

  async function handleApproval(id: any) {
    await axiosConfig
      .post(`/customer-trucks/approve?customerTruckId=${id}`)
      .then((response: any) => {
        message.success("Truck approved successfully");
        getTruck();
      })
      .catch((error) => {
        requestErrorHandler(error);
      });
  }

  async function handleRejection(id: any) {
    await axiosConfig
      .post(`/customer-trucks/reject?customerTruckId=${id}`)
      .then((response: any) => {
        message.success("Truck rejected successfully");
        getTruck();
      })
      .catch((error) => {
        requestErrorHandler(error);
      });
  }

  async function handleAvailability(id: any) {
    await axiosConfig
      .post(`/customer-trucks/toggle-truck?customerTruckId=${id}`)
      .then((response: any) => {
        message.success("Truck availability updated successfully");
        getTruck();
      })
      .catch((error) => {
        requestErrorHandler(error);
      });
  }

  async function handleDelete(id: any) {
    await axiosConfig
      .delete(`/customer-trucks/delete?truckId=${id}`)
      .then((response: any) => {
        message.success("Truck deleted successfully");
        getTruck();
      })
      .catch((error) => {
        requestErrorHandler(error);
      });
  }

  useEffect(() => {
    getTruck();
  }, [getTruck]);

  const showAttachButton =
    !truck?.deviceObjectId && truck.status === "approved";

  const showChangeDeviceButton =
    truck?.deviceObjectId && truck.status === "approved";

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="my-8">
          <h2 className="text-lg font-semibold">Trucks</h2>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={"/admin/trucks"}>Trucks</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{truck?.brand}</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div>{/* <AddTruck getTrucks={getTrucks} /> */}</div>
      </div>
      <div className="site-layout-background w-full overflow-auto">
        {isLoading ? (
          <Skeleton active paragraph={{ rows: 8 }} />
        ) : (
          <div className="">
            <div className="flex gap-2 my-2 justify-end">
              <EditTruck truck={truck} getTrucks={getTruck} />
              {truck.status !== "approved" && (
                <Button
                  type="primary"
                  className="bg-[#096DD9]"
                  loading={isLoading}
                  disabled={isLoading}
                  onClick={() => handleApproval(truck.id)}
                >
                  Approve
                </Button>
              )}
              {showAttachButton && (
                <Button
                  onClick={(e) => {
                    setShowDeviceModal(true);
                  }}
                >
                  Attach Device
                </Button>
              )}{" "}
              {showChangeDeviceButton && (
                <Button
                  onClick={(e) => {
                    setShowDeviceModal(true);
                  }}
                >
                  Change Device
                </Button>
              )}
              {truck.status === "approved" && (
                <Button
                  type="primary"
                  danger
                  loading={isLoading}
                  disabled={isLoading}
                  onClick={() => handleRejection(truck.id)}
                >
                  Suspend
                </Button>
              )}
              {truck.isAvailable && (
                <Button
                  type="primary"
                  danger
                  loading={isLoading}
                  disabled={isLoading}
                  onClick={() => handleAvailability(truck.id)}
                >
                  Disable
                </Button>
              )}
              {!truck.isAvailable && (
                <Button
                  type="primary"
                  className="bg-[#096DD9]"
                  loading={isLoading}
                  disabled={isLoading}
                  onClick={() => handleAvailability(truck.id)}
                >
                  Enable
                </Button>
              )}
              <Popconfirm
                title={`Are you sure you want to delete ${truck.name}?`}
                onConfirm={() => {
                  handleDelete(truck.id);
                }}
                okText="Delete"
                cancelText="Cancel"
              >
                <Button type="primary" danger>
                  <DeleteOutlined />
                  <span className="">Delete</span>
                </Button>
              </Popconfirm>
              <AddDevice
                open={showDeviceModal}
                onCancel={() => setShowDeviceModal(false)}
                truckInfo={truck}
              />
            </div>
            <div className="w-full flex justify-between gap-4">
              <div className="w-1/2 bg-white p-8 rounded-md">
                <div className="w-full flex justify-center">
                  <img
                    src={truck.picture}
                    alt={truck.name}
                    className="w-full h-96 object-cover"
                  />
                </div>
                <div className="w-full flex justify-center mt-2">
                  <Switch
                    checked={truck.isAvailable ? true : false}
                    checkedChildren={<>ON</>}
                    unCheckedChildren={<>OFF</>}
                    disabled={isLoading}
                    onChange={() => handleAvailability(truck?.id)}
                  />
                </div>
                <div className="w-full">
                  <Button
                    size="large"
                    style={{ padding: 0 }}
                    type="link"
                    onClick={() => setShowDriverModal(true)}
                  >
                    View Driver Information
                  </Button>
                  <br />
                  {truck?.deviceObjectId && (
                    <Button
                      size="large"
                      style={{ padding: 0 }}
                      type="link"
                      onClick={() =>
                        navigate(
                          `/admin/trucks/tracking/${truckId}?deviceObjectId=${truck?.deviceObjectId}`
                        )
                      }
                    >
                      View Live Tracking
                    </Button>
                  )}
                  <Modal
                    footer={false}
                    open={showDriverModal}
                    onCancel={() => setShowDriverModal(false)}
                  >
                    <div className="flex items-center justify-center p-8">
                      <div>
                        <h4 className="font-semibold text-2xl ">
                          Driver Information
                        </h4>
                        <div className="flex items-center gap-4 mt-4">
                          <div className="bg-blue-200 p-1 rounded-full">
                            <Avatar
                              shape="square"
                              size={100}
                              src={truck.driverPhoto ?? avatar}
                            ></Avatar>
                          </div>
                          <div className="space-y-2">
                            <p>
                              Driver Name: <strong>{truck.driverName}</strong>
                            </p>
                            <p>
                              Driver Phone: <strong>{truck.driverPhone}</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal>
                </div>
              </div>

              <div className="w-1/2 flex items-center bg-white p-8 rounded-md">
                <table className="w-full">
                  <tbody>
                    <tr className="w-full">
                      <td className="w-1/2 py-3">
                        <strong>Tonnage: </strong>
                      </td>
                      <td className="w-1/2">{truck.tonnage}</td>
                    </tr>
                    <tr className="w-full">
                      <td className="w-1/2 py-3">
                        <strong>Brand: </strong>
                      </td>
                      <td className="w-1/2">{truck.brand}</td>
                    </tr>
                    <tr className="w-full">
                      <td className="w-1/2 py-3">
                        <strong>Model: </strong>
                      </td>
                      <td className="w-1/2">{truck.model}</td>
                    </tr>
                    <tr className="w-full">
                      <td className="w-1/2 py-3">
                        <strong>Head color: </strong>
                      </td>
                      <td className="w-1/2">{truck.headColor}</td>
                    </tr>
                    <tr className="w-full">
                      <td className="w-1/2 py-3">
                        <strong>Bucket color: </strong>
                      </td>
                      <td className="w-1/2">{truck.bucketColor}</td>
                    </tr>
                    <tr className="w-full">
                      <td className="w-1/2 py-3">
                        <strong>Plate number: </strong>
                      </td>
                      <td className="w-1/2">{truck.plateNumber}</td>
                    </tr>
                    <tr className="w-full">
                      <td className="w-1/2 py-3">
                        <strong>Status: </strong>
                      </td>
                      <td className="w-1/2">{truck.status}</td>
                    </tr>
                    <tr className="w-full">
                      <td className="w-1/2 py-3">
                        <strong>Availability: </strong>
                      </td>
                      <td className="w-1/2">
                        {truck.isAvailable ? "Yes" : "No"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ShowTruck;
