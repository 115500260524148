import React, { useState } from "react";
import { Breadcrumb, Skeleton } from "antd";
import { Link, useSearchParams } from "react-router-dom";

const ShowEnquiry = () => {
    const [isLoading] = useState(false);
    const [searchParams] = useSearchParams();


    const formatDate = (unformattedDate: string) =>{
        let date = new Date(unformattedDate);
        let formattedDate = date.toLocaleDateString('en-NG');
        return formattedDate;
    }
    return (
        <div>
            <div className="flex items-center justify-between">
                <div className="my-8">
                    <h2 className="text-lg font-semibold">Enquiries</h2>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to={"/admin"}>Dashboard</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={"/admin/enquiries"}>Enquiries</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>Enquiry</Breadcrumb.Item>
                    </Breadcrumb>
                </div>

            </div>
            <div className="site-layout-background w-full overflow-auto">
                {isLoading ? (
                    <Skeleton active paragraph={{ rows: 8 }} />
                ) : (<>
                    <div>
                        <div className="flex justify-between">
                            <h4 className="text-lg font-semibold text-gray-600">
                                Enquiry Information
                            </h4>
                        </div>
                        <div className="flex items-center bg-white p-8 rounded-md">
                            <table className="w-full">
                                <tbody>
                                    <tr className="w-full">
                                        <td className="w-1/2 py-3">
                                            <strong>Name: </strong>
                                        </td>
                                        <td className="w-1/2">
                                            {searchParams.get('name')}
                                        </td>
                                    </tr>
                                    <tr className="w-full">
                                        <td className="w-1/2 py-3">
                                            <strong>Email: </strong>
                                        </td>
                                        <td className="w-1/2">{searchParams.get('email')}</td>
                                    </tr>
                                    <tr className="w-full">
                                        <td className="w-1/2 py-3">
                                            <strong>Phone Number: </strong>
                                        </td>
                                        <td className="w-1/2">{searchParams.get('phoneNumber')}</td>
                                    </tr>
                                    <tr className="w-full">
                                        <td className="w-1/2 py-3">
                                            <strong>Date Submitted: </strong>
                                        </td>
                                        <td className="w-1/2">{formatDate(searchParams.get('createdAt')!)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div>
                        <div className="flex justify-between">
                            <h4 className="text-lg font-semibold text-gray-600">
                                Enquiry
                            </h4>
                        </div>
                        <div className="flex items-center bg-white p-8 rounded-md">
                            <p>
                                {searchParams.get('report')}
                            </p>
                        </div>
                    </div>
                </>
                )}
            </div>
        </div>
    );
};

export default ShowEnquiry;
