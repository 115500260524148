import React, { useEffect, useState } from "react";
import {
  AutoComplete,
  Button,
  Drawer,
  Form,
  Input,
  message,
  Select,
} from "antd";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { useCallback } from "react";
import { EditOutlined } from "@ant-design/icons";
import axiosConfig from "../../../util/axiosConfig";
import TextArea from "antd/es/input/TextArea";
import { ZonesType } from "../../../shared/interfaces/zone.interface";
import requestErrorHandler from "../../../util/requestErrorHandler";

const EditZone = ({ zone, getZones }: { zone: ZonesType; getZones: any }) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    []
  );
  const [form] = Form.useForm();
  const { clearSuggestions } = usePlacesAutocomplete();

  async function handleSubmit() {
    setIsLoading(true);
    await axiosConfig
      .put(`/zones/update?zoneId=${zone.id}`, form.getFieldsValue())
      .then((response: any) => {
        message.success("Zone updated successfully");
        form.resetFields();
        getZones();
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setOpen(false);
        setIsLoading(false);
      });
  }

  const handleSearch = (value: string) => {
    let res: { value: string; label: string }[] = [];
    if (!value || value.indexOf("@") >= 0) {
      res = [];
    } else {
      res = ["gmail.com", "163.com", "qq.com"].map((domain) => ({
        value,
        label: `${value}@${domain}`,
      }));
    }
    setOptions(res);
  };

  // async function handleSearch(value: string) {
  //   setValue(value);

  //   const optionSet: { value: string }[] = [];

  //   data.map((place: any) => optionSet.push({ value: place.description }));
  //   setOptions(optionSet);

  // }

  async function handleSelect(value: string, field: any) {
    const parameter = {
      address: value,
    };

    getGeocode(parameter)
      .then((results) => {
        const { lat, lng } = getLatLng(results[0]);

        form.setFieldValue(field, lat + ", " + lng);
      })
      .catch((error) => {})
      .finally(() => clearSuggestions());
  }

  const handleGeolocate = useCallback(
    async (value: string, field: any) => {
      const geocoder = new google.maps.Geocoder();
      const latlngStr = value.split(",", 2);
      const latlng = {
        lat: parseFloat(latlngStr[0]),
        lng: parseFloat(latlngStr[1]),
      };
      geocoder
        .geocode({ location: latlng })
        .then((response: any) => {
          if (response.results[0]) {
            const address = response.results[0].formatted_address;
            form.setFieldValue(field, address);
          } else {
          }
        })
        .catch((e: any) => {});
    },
    [form]
  );

  useEffect(() => {
    handleGeolocate(zone.southWestBound, "southWestAddress");
    handleGeolocate(zone.northEastBound, "northEastAddress");
  }, [handleGeolocate, zone.southWestBound, zone.northEastBound]);

  return (
    <>
      <Button
        type="primary"
        icon={<EditOutlined />}
        onClick={() => setOpen(true)}
        style={{ background: "orange", borderColor: "orange" }}
      >
        Edit
      </Button>
      <Drawer
        title="Edit Zone"
        placement="right"
        onClose={() => setOpen(false)}
        open={open}
      >
        <Form
          initialValues={zone}
          className="w-100"
          onFinish={handleSubmit}
          form={form}
          layout="vertical"
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: "Enter a valid name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="zoneType"
            label="Zone Type"
            rules={[{ required: true, message: "Select a valid zone type" }]}
          >
            <Select
              defaultValue="standard"
              options={[
                {
                  value: "standard",
                  label: "Standard",
                },
                {
                  value: "quarry",
                  label: "Quarry",
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true }]}
          >
            <TextArea />
          </Form.Item>
          <Form.Item
            name="northEastAddress"
            label="North East Address"
            rules={[{ required: true, message: "Enter a valid address" }]}
          >
            <AutoComplete
              onSearch={handleSearch}
              onSelect={(enterValue: string) => {
                handleSelect(enterValue, "northEastBound");
              }}
              placeholder="Search place"
              options={options}
            />
          </Form.Item>
          <Form.Item
            name="northEastBound"
            label="North East Bound"
            rules={[{ required: true, message: "Enter a valid bound" }]}
          >
            <Input
              placeholder="7.3338, 7.89009"
              onChange={(e) => {
                handleGeolocate(e.target.value, "northEastAddress");
              }}
            />
          </Form.Item>
          <Form.Item
            name="southWestAddress"
            label="South West Address"
            rules={[{ required: true, message: "Enter a valid address" }]}
          >
            <AutoComplete
              onSearch={handleSearch}
              onSelect={(enterValue: string) => {
                handleSelect(enterValue, "southWestBound");
              }}
              placeholder="Search place"
              options={options}
            />
          </Form.Item>
          <Form.Item
            name="southWestBound"
            label="South West Bound"
            rules={[{ required: true, message: "Enter a valid bound" }]}
          >
            <Input
              placeholder="7.3338, 7.89009"
              onChange={(e) => {
                handleGeolocate(e.target.value, "southWestAddress");
              }}
            />
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            block
            size="large"
            loading={isLoading}
            className="bg-[#096DD9]"
          >
            Save
          </Button>
        </Form>
      </Drawer>
    </>
  );
};

export default EditZone;
