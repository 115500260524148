import { MappedZone } from "./zone.interface";

export interface MaterialsType {
  id: string;
  name: string;
  mappedZones: MappedZone[];
}

export const DefaultMaterials = {
  id: '',
  name: '',
  mappedZones: [],
};

