import React, { useEffect, useState } from "react";
import { Breadcrumb, message, Skeleton, Table, Tabs, TabsProps } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useCallback } from "react";
import axiosConfig from "../../../util/axiosConfig";
import { Link } from "react-router-dom";
import {
  InitiatedOrder,
  OrdersType,
} from "../../../shared/interfaces/requests.interface";
import { getAllRequest } from "../../../services/requestService";
import requestErrorHandler from "../../../util/requestErrorHandler";

// const Requests = () => {
const Requests: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [page] = React.useState(1);
  const [paginationSize] = useState(10);
  const [orders, setOrders] = useState<OrdersType[]>([]);
  const [initiatedOrders, setInitiatedOrders] = useState<InitiatedOrder[]>([]);
  const [paymentStatus] = useState(true);

  const columns: ColumnsType<OrdersType> = [
    {
      title: "#",
      dataIndex: "id",
      render: (_, record, index) => (page - 1) * paginationSize + index + 1,
    },
    {
      title: "Date Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, record) => (
        <div>
          {new Date(record.createdAt).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })}
        </div>
      ),
    },
    {
      title: "Rental ID",
      dataIndex: "rentalId",
      key: "rentalId",
      render: (_, record, index) => (
        <Link to={"/admin/requests/" + record.id}>
          {record.rentalId ?? record.orderId}
        </Link>
      ),
    },
    {
      title: "Type",
      dataIndex: "orderType",
      key: "orderType",
      render: (_, record, index) => (
        <span className="capitalize">{record.orderType}</span>
      ),
    },
    {
      title: "No Of Trips",
      dataIndex: "noOfTrips",
      key: "noOfTrips",
    },
    {
      title: "Rental Status",
      dataIndex: "orderStatus",
      key: "orderStatus",
      render: (_, record, index) => (
        <span className="capitalize">{record.orderStatus}</span>
      ),
    },
    {
      title: "Tracking Status",
      dataIndex: "trackingStatus",
      key: "trackingStatus",
      render: (_, record, index) => (
        <span className="capitalize">{record.trackingStatus}</span>
      ),
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      render: (_, record) => (
        <div>{record.paymentStatus === true ? "Paid" : "Pending"}</div>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (_, record) => (
        <div>
          {Number(record.price).toLocaleString("en-NG", {
            style: "currency",
            currency: "NGN",
          })}
        </div>
      ),
    },
  ];

  const initiatedColumns: ColumnsType<InitiatedOrder> = [
    {
      title: "#",
      dataIndex: "id",
      render: (_, record, index) => (page - 1) * paginationSize + index + 1,
    },
    {
      title: "Date Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, record) => (
        <div>
          {new Date(record.createdAt).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })}
        </div>
      ),
    },
    {
      title: "Partner",
      dataIndex: "partnerName",
      key: "partnerName",
      render: (_, record, index) => (
        <Link to={`/admin/partners/${record.partnerId}?fromRequest=true`}>
          {record.partnerId}
        </Link>
      ),
    },
    {
      title: "No Of Trips",
      dataIndex: "noOfTrips",
      key: "noOfTrips",
    },
    {
      title: "Pickup Point",
      dataIndex: "pickUpPoint",
      key: "pickUpPoint",
    },
    {
      title: "Delivery Point",
      dataIndex: "deliveryPoint",
      key: "deliveryPoint",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (_, record) => (
        <div>
          {Number(record.price).toLocaleString("en-NG", {
            style: "currency",
            currency: "NGN",
          })}
        </div>
      ),
    },
  ];

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Requests`,
      children: <Table columns={columns} dataSource={orders} rowKey="id" />,
    },
    {
      key: "2",
      label: `Partner Initiated Request`,
      children: (
        <Table
          columns={initiatedColumns}
          dataSource={initiatedOrders}
          rowKey="id"
        />
      ),
    },
  ];

  const getOrders = useCallback(async () => {
    setIsLoading(true);

    const requests = await getAllRequest(paymentStatus);

    setOrders(requests);

    setIsLoading(false);
  }, [paymentStatus, setIsLoading, setOrders]);

  async function getInitiatedOrder() {
    setIsLoading(true);
    const response = await axiosConfig.get("partner/initiated-order");
    const orders = response?.data?.allInitiatedOrders;

    if (!orders) {
      message.error("Unable to fetch orders");
    }

    const _orders = orders?.sort(
      (reqFirst: OrdersType, reqSec: OrdersType) =>
        new Date(reqSec.createdAt).getTime() -
        new Date(reqFirst.createdAt).getTime()
    );

    setInitiatedOrders(_orders);

    setIsLoading(false);
  }

  async function handleApprove(id: any) {
    await axiosConfig
      .post(`/order/accept-order-request`, {
        orderRequestId: id,
      })
      .then((response: any) => {
        message.success("Order accepted successfully");
        getOrders();
      })
      .catch((error) => {
        requestErrorHandler(error);
      });
  }

  async function handleDecline(id: any) {
    await axiosConfig
      .post(`/order/reject-order-request`, {
        orderRequestId: id,
      })
      .then((response: any) => {
        message.success("Order declined successfully");
        getOrders();
      })
      .catch((error) => {
        requestErrorHandler(error);
      });
  }

  useEffect(() => {
    getOrders();
    getInitiatedOrder();
  }, [getOrders]);

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="my-8">
          <h2 className="text-lg font-semibold">Requests</h2>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={"/admin"}>Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Requests</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div>{/* <AddPartner getOrders={getOrders} /> */}</div>
      </div>
      {/* <div>
        <Select
          onChange={(e) => {
            setPaymentStatus(e.target.value)
            getOrders();
          }}
          defaultValue='true'
          options={[
            {
              value: 'true',
              label: "True",
            },
            {
              value: 'false',
              label: "False",
            },
          ]}
        />
      </div> */}
      <div className="site-layout-background w-full overflow-auto bg-white p-4">
        {isLoading ? (
          <Skeleton active paragraph={{ rows: 8 }} />
        ) : (
          <Tabs defaultActiveKey="1" items={items} />
        )}
      </div>
    </div>
  );
};

export default Requests;
