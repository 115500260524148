import React, { useEffect, useState } from "react";
import {
  Avatar,
  Breadcrumb,
  Button,
  Divider,
  message,
  Modal,
  Popconfirm,
  Skeleton,
  Space,
  Tooltip,
} from "antd";
import axiosConfig from "../../../util/axiosConfig";
import {
  DefaultOrders,
  OrdersType,
} from "../../../shared/interfaces/requests.interface";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import avatar from "../../../assets/avatar.png";
import { formatToNaira } from "../../../util/money";
import OrderTracking from "../../../components/OrderTracking";
import TrackingButton from "../../../components/TrackingButton";
import dayjs from "dayjs";
import { useCallback } from "react";
import { RenteesType } from "../../../shared/interfaces/truckowners.interface";
import { RentersType } from "../../../shared/interfaces/renters.interface";
import OrderReasign from "../../../components/OrderReasign";
import { PartnersType } from "../../../shared/interfaces/partners.interface";
import AddOpex from "./AddOpex";
import requestErrorHandler from "../../../util/requestErrorHandler";

const ShowRequest = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showDriverModal, setShowDriverModal] = useState(false);
  const { requestId } = useParams();
  const [request, setRequest] = useState<OrdersType>(DefaultOrders);
  const [rentee, setRentee] = useState<RenteesType>();
  const [renter, setRenter] = useState<RentersType>();
  const [partner, setPartner] = useState<PartnersType>();

  const getTruckOwner = useCallback(async (truckOwnerId: string) => {
    setIsLoading(true);
    await axiosConfig
      .get("/truck-owners/get-one?truckOwnerId=" + truckOwnerId)
      .then((response: any) => {
        setRentee(response.data.truckOwner);
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRenter = useCallback(async (renterId: string) => {
    setIsLoading(true);
    await axiosConfig
      .get("/renters/get-one?renterId=" + renterId)
      .then((response: any) => {
        setRenter(response.data.renter);
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const getPartner = useCallback(async (renterId: string) => {
    setIsLoading(true);
    await axiosConfig
      .get("partner/get-one?partnerId=" + renterId)
      .then((response: any) => {
        setPartner(response.data.data);
      })
      .catch((error) => {
        requestErrorHandler(error);
      });
    setIsLoading(false);
  }, []);

  const getRequest = async () => {
    setIsLoading(true);
    try {
      const response = await axiosConfig.get(
        "order/order-request?requestId=" + requestId
      );
      const request = response?.data?.orderRequest;
      if (!request) {
        message.error("Unable to fetch request");
      } else {
        setRequest(request);
        const truckOwnerId = request?.truckOwnerId;
        const renterId = request?.renterId;
        getTruckOwner(truckOwnerId);
        if (request.orderType === "renter") {
          getRenter(renterId);
        } else {
          getPartner(renterId);
        }
      }
    } catch (error) {
      requestErrorHandler(error);
    } finally {
      setIsLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  async function handleApprove(id: any) {
    setIsLoading(true);
    try {
      await axiosConfig.post(`/order/accept-order-request`, {
        orderRequestId: id,
      });

      message.success("Order accepted successfully");
      getRequest();
    } catch (error) {
      requestErrorHandler(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleDecline(id: any) {
    setIsLoading(true);
    try {
      await axiosConfig.post(`/order/reject-order-request`, {
        orderRequestId: id,
      });
      message.success("Order declined successfully");
      getRequest();
    } catch (error) {
      requestErrorHandler(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function updateTrip(id: any) {
    setIsLoading(true);
    try {
      await axiosConfig.post(`/order/update-trip`, {
        orderRequestId: id,
      });
      message.success("Trip updated");
      getRequest();
    } catch (error) {
      requestErrorHandler(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    Promise.all([getRequest()]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showUpdateTrip =
    request?.orderStatus === "processing" &&
    request?.trackingStatus !== "delivered";

  const showSetDelivered =
    request.noOfTrips === request.currentTrip &&
    request?.orderStatus !== "pending";

  const isLastTrip = request.noOfTrips === request.currentTrip;
  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="my-8">
          <h2 className="text-lg font-semibold">Requests</h2>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={"/admin"}>Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={"/admin/requests"}>Requests</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Request</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        {/* <div>{formatToNaira(request?.price ?? 0)}</div> */}
      </div>
      <div className="site-layout-background w-full overflow-auto">
        {isLoading ? (
          <Skeleton active paragraph={{ rows: 8 }} />
        ) : (
          <div>
            {request?.orderStatus !== "cancelled" &&
              request?.orderStatus !== "pending" && (
                <div className="mb-4">
                  <AddOpex request={request} getRequest={getRequest} />
                </div>
              )}
            <div className="bg-white p-8">
              <div>
                <div className="flex gap-4">
                  <span className="font-semibold">Total Price:</span>
                  <span> {formatToNaira(request?.price)}</span>
                </div>
                {request?.operatingExpense > 0 && (
                  <div className="flex gap-4">
                    <span className="font-semibold">Operation Expenses:</span>
                    <span> {formatToNaira(request?.operatingExpense)}</span>
                  </div>
                )}
              </div>
              <section className="my-4">
                <Space>
                  {showUpdateTrip && (
                    <div>
                      {request?.currentTrip < 1
                        ? ` No trip in progress `
                        : ` Trip ${request?.currentTrip} in progress `}
                      <Button
                        disabled={isLastTrip}
                        onClick={() => updateTrip(request.id)}
                        hidden={isLastTrip}
                      >
                        UPDATE TRIP
                      </Button>
                    </div>
                  )}
                  {showSetDelivered && (
                    <TrackingButton
                      trackingStatus={request?.trackingStatus ?? "pending"}
                      orderRequestId={requestId!}
                      callback={getRequest}
                    />
                  )}
                  {request?.orderStatus === "pending" && (
                    <>
                      <Popconfirm
                        title={`Are you sure you want to decline this request?`}
                        onConfirm={() => {
                          handleDecline(request.id);
                        }}
                        okText="Decline"
                        cancelText="Cancel"
                      >
                        <Button type="primary" danger>
                          <CloseCircleOutlined />
                          <span className="">Decline</span>
                        </Button>
                      </Popconfirm>

                      <Popconfirm
                        title={`Are you sure you want to approve this request?`}
                        onConfirm={() => {
                          handleApprove(request.id);
                        }}
                        okText="Aprove"
                        cancelText="Cancel"
                      >
                        <Button type="primary">
                          <CheckCircleOutlined />
                          <span className="">Accept</span>
                        </Button>
                      </Popconfirm>
                    </>
                  )}
                </Space>
              </section>
              {request?.orderStatus === "cancelled" && (
                <div>
                  <OrderReasign request={request} getRequest={getRequest} />
                </div>
              )}
              <Divider />
              {/* {request?.orderStatus !== "pending" && (
                <OrderTracking orderStatus={request?.trackingStatus!} />
              )} */}
              <section className="flex justify-between gap-8">
                <aside className="w-1/2 flex flex-col space-y-4">
                  <span className="flex justify-between">
                    <strong> Rental Id: </strong>
                    <span>{request?.rentalId}</span>
                  </span>
                  <span className="flex justify-between">
                    <strong> Date created: </strong>
                    <span>
                      {dayjs(request?.createdAt).format("DD MMM YYYY")}
                    </span>
                  </span>
                  <span className="flex justify-between">
                    <strong> Date Due: </strong>
                    <span>{dayjs(request?.dateDue).format("DD MMM YYYY")}</span>
                  </span>
                  <span className="flex justify-between">
                    <strong> Material: </strong>
                    <span style={{ textTransform: "capitalize" }}>
                      {request?.material?.name ?? "--"}
                    </span>
                  </span>
                </aside>
                <aside className="w-1/2 flex flex-col space-y-4">
                  <span className="flex justify-between">
                    <strong> Tonnage: </strong>
                    <span>{`${
                      request?.customerTruck?.tonnage ?? "--"
                    } Tonnes`}</span>
                  </span>
                  <span className="flex justify-between">
                    <strong> Number of Trips: </strong>
                    <span>{request?.noOfTrips!}</span>
                  </span>
                  <span className="flex justify-between">
                    <strong> Delivery Address: </strong>
                    <span>{request?.deliveryPoint!}</span>
                  </span>
                  <span className="flex justify-between">
                    <strong> Pickup Address: </strong>
                    <span>{request?.pickUpPoint!}</span>
                  </span>
                </aside>
              </section>
            </div>

            <div className="w-full flex justify-between gap-4 mt-4">
              <div className="w-1/2 bg-white p-8 rounded-md">
                <div className="w-full flex justify-center">
                  <img
                    src={request?.customerTruck?.picture}
                    alt={request?.customerTruck?.name}
                    className="w-80 h-80 object-cover"
                  />
                </div>
                <div className="w-full mt-2">
                  <Button
                    size="large"
                    style={{ padding: 0 }}
                    type="link"
                    onClick={() => setShowDriverModal(true)}
                  >
                    View Driver Information
                  </Button>
                  <br />
                  <Button
                    size="large"
                    style={{ padding: 0 }}
                    type="link"
                    onClick={() =>
                      navigate(
                        `/admin/trucks/tracking/${request?.customerTruck?.id}?deviceObjectId=${request?.customerTruck?.deviceObjectId}`
                      )
                    }
                  >
                    View Live Tracking
                  </Button>

                  <Modal
                    footer={false}
                    open={showDriverModal}
                    onCancel={() => setShowDriverModal(false)}
                  >
                    <div className="flex items-center justify-center p-8">
                      <div>
                        <h4 className="font-semibold text-2xl ">
                          Driver Information
                        </h4>
                        <div className="flex items-center gap-4 mt-4">
                          <div className="bg-blue-200 p-1 rounded-full">
                            <Avatar
                              shape="square"
                              size={100}
                              src={
                                request?.customerTruck?.driverPhoto ?? avatar
                              }
                            ></Avatar>
                          </div>
                          <div className="space-y-2">
                            <p>
                              Driver Name:{" "}
                              <strong>
                                {request?.customerTruck?.driverName}
                              </strong>
                            </p>
                            <p>
                              Driver Phone:{" "}
                              <strong>
                                {request?.customerTruck?.driverPhone}
                              </strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal>
                </div>
              </div>
              <div className="w-1/2 flex items-center bg-white p-8 rounded-md">
                <table className="w-full">
                  <tbody>
                    <tr className="w-full">
                      <td className="w-1/2 py-3">
                        <strong>Name: </strong>
                      </td>
                      <td className="w-1/2">{request?.customerTruck?.name}</td>
                    </tr>
                    <tr className="w-full">
                      <td className="w-1/2 py-3">
                        <strong>Tonnage: </strong>
                      </td>
                      <td className="w-1/2">
                        {request?.customerTruck?.tonnage}
                      </td>
                    </tr>
                    <tr className="w-full">
                      <td className="w-1/2 py-3">
                        <strong>Brand: </strong>
                      </td>
                      <td className="w-1/2">{request?.customerTruck?.brand}</td>
                    </tr>
                    <tr className="w-full">
                      <td className="w-1/2 py-3">
                        <strong>Model: </strong>
                      </td>
                      <td className="w-1/2">{request?.customerTruck?.model}</td>
                    </tr>
                    <tr className="w-full">
                      <td className="w-1/2 py-3">
                        <strong>Head color: </strong>
                      </td>
                      <td className="w-1/2">
                        {request?.customerTruck?.headColor}
                      </td>
                    </tr>
                    <tr className="w-full">
                      <td className="w-1/2 py-3">
                        <strong>Bucket color: </strong>
                      </td>
                      <td className="w-1/2">
                        {request?.customerTruck?.bucketColor}
                      </td>
                    </tr>
                    <tr className="w-full">
                      <td className="w-1/2 py-3">
                        <strong>Plate number: </strong>
                      </td>
                      <td className="w-1/2">
                        {request?.customerTruck?.plateNumber}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="w-full flex justify-between gap-4 mt-4">
              <div className="w-1/2 bg-white p-8 rounded-md">
                <h2 className="text-lg font-semibold text-gray-600">
                  Truck Owner
                </h2>
                <table className="w-full">
                  <tbody>
                    <tr className="w-full">
                      <td className="w-1/2 py-3">
                        <strong>Name: </strong>
                      </td>
                      <td className="w-1/2">
                        {rentee?.adminFirstName} {rentee?.adminLastName}
                      </td>
                    </tr>
                    <tr className="w-full">
                      <td className="w-1/2 py-3">
                        <strong>Business name: </strong>
                      </td>
                      <td className="w-1/2">{rentee?.businessName}</td>
                    </tr>
                    <tr className="w-full">
                      <td className="w-1/2 py-3">
                        <strong>Address: </strong>
                      </td>
                      <td className="w-1/2">{rentee?.businessAddress}</td>
                    </tr>
                    <tr className="w-full">
                      <td className="w-1/2 py-3">
                        <strong>Phone number: </strong>
                      </td>
                      <td className="w-1/2">{rentee?.contactNumber}</td>
                    </tr>
                    <tr className="w-full">
                      <td className="w-1/2 py-3">
                        <strong>Email: </strong>
                      </td>
                      <td className="w-1/2">{rentee?.adminEmail}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {request.orderType === "renter" ? (
                <div className="w-1/2 items-center bg-white p-8 rounded-md">
                  <h2 className="text-lg font-semibold text-gray-600">
                    Renter
                  </h2>
                  <table className="w-full">
                    <tbody>
                      <tr className="w-full">
                        <td className="w-1/2 py-3">
                          <strong>Name: </strong>
                        </td>
                        <td className="w-1/2">
                          {renter?.firstName} {renter?.lastName}
                        </td>
                      </tr>
                      <tr className="w-full">
                        <td className="w-1/2 py-3">
                          <strong>Address: </strong>
                        </td>
                        <td className="w-1/2">{renter?.businessAddress}</td>
                      </tr>
                      <tr className="w-full">
                        <td className="w-1/2 py-3">
                          <strong>Phone number: </strong>
                        </td>
                        <td className="w-1/2">{renter?.phoneNumber}</td>
                      </tr>
                      <tr className="w-full">
                        <td className="w-1/2 py-3">
                          <strong>Email: </strong>
                        </td>
                        <td className="w-1/2">{renter?.email}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="w-1/2 items-center bg-white p-8 rounded-md">
                  <h2 className="text-lg font-semibold text-gray-600">
                    Partner
                  </h2>
                  <table className="w-full">
                    <tbody>
                      <tr className="w-full">
                        <td className="w-1/2 py-3">
                          <strong>Business name: </strong>
                        </td>
                        <td className="w-1/2">{partner?.businessName}</td>
                      </tr>
                      <tr className="w-full">
                        <td className="w-1/2 py-3">
                          <strong>Address: </strong>
                        </td>
                        <td className="w-1/2">{partner?.businessAddress}</td>
                      </tr>
                      <tr className="w-full">
                        <td className="w-1/2 py-3">
                          <strong>Email: </strong>
                        </td>
                        <td className="w-1/2">{partner?.email}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ShowRequest;
