import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  message,
  Popconfirm,
  Skeleton,
  Space,
  Table,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { DeleteOutlined } from "@ant-design/icons";
import axiosConfig from "../../../util/axiosConfig";
import AddMaterial from "./AddMaterial";
import EditMaterial from "./EditMaterial";
import { Link } from "react-router-dom";
import { MaterialsType } from "../../../shared/interfaces/material.interface";
import requestErrorHandler from "../../../util/requestErrorHandler";

const Materials = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [page] = React.useState(1);
  const [paginationSize] = useState(10);
  const [materials, setMaterials] = useState([]);

  const columns: ColumnsType<MaterialsType> = [
    {
      title: "#",
      dataIndex: "id",
      render: (_, record, index) => (page - 1) * paginationSize + index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, record, index) => (
        <Link to={"/admin/materials/" + record.id}>{record.name}</Link>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <EditMaterial material={record} getMaterials={getMaterials} />
          <Popconfirm
            title={`Are you sure you want to delete ${record.name} material?`}
            onConfirm={() => {
              handleDelete(record.id);
            }}
            okText="Delete"
            cancelText="Cancel"
          >
            <Button type="primary" danger>
              <DeleteOutlined />
              <span className="">Delete</span>
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  async function getMaterials() {
    setIsLoading(true);
    await axiosConfig
      .get("materials/get-all")
      .then((response: any) => {
        setMaterials(response.data.data);
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  async function handleDelete(id: any) {
    await axiosConfig
      .delete(`/materials/delete?materialId=${id}`)
      .then((response: any) => {
        message.success("Material deleted successfully");
        getMaterials();
      })
      .catch((error) => {
        requestErrorHandler(error);
      });
  }

  useEffect(() => {
    getMaterials();
  }, []);

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="my-8">
          <h2 className="text-lg font-semibold">Materials</h2>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={"/admin"}>Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Materials</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div>
          <AddMaterial getMaterials={getMaterials} />
        </div>
      </div>
      <div className="site-layout-background w-full overflow-auto bg-white">
        {isLoading ? (
          <Skeleton active paragraph={{ rows: 8 }} />
        ) : (
          <Table columns={columns} dataSource={materials} rowKey="id" />
        )}
      </div>
    </div>
  );
};

export default Materials;
