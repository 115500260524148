import React, { memo, useEffect, useMemo, useState } from "react";
import {
  AdvancedMarker,
  Map,
  MapCameraChangedEvent,
} from "@vis.gl/react-google-maps";
import TruckMarker from "./Icon/TruckMarker";

const MapView = ({ devicePosition }: { devicePosition: DevicePosition }) => {
  // const [location, setLocation] = useState<any>({ lat: 0, lng: 0 });

  const [count, setCount] = useState<number>(0);
  // const [center, set]

  const location = useMemo(() => {
    setCount((prev) => prev + 1);
    return {
      lat: devicePosition?.Latitude,
      lng: devicePosition?.Longitude,
    };
  }, [devicePosition]);

  // useEffect(() => {}, []);

  const center = useMemo(() => {
    if (count === 3) {
      setCount(0);
      return {
        lat: devicePosition?.Latitude,
        lng: devicePosition?.Longitude,
      };
    }
  }, [devicePosition]);

  return (
    <Map
      defaultZoom={16.9}
      center={{ ...location }}
      mapId="27cd1eea83179dcb"
      controlSize={20}
      controlled={false}
      zoomControl={false}
    >
      <PoiMarkers course={devicePosition?.Course!} poi={{ location }} />
    </Map>
  );
};

const PoiMarkers = (props: { course: number; poi: { location: any } }) => {
  return (
    <>
      <AdvancedMarker position={props.poi.location}>
        <TruckMarker rotate={props.course} />
        {/* <Pin background={"#FBBC04"} glyphColor={"#000"} borderColor={"#000"} /> */}
      </AdvancedMarker>
    </>
  );
};

export default MapView;
