import { useEffect, useRef, useState } from "react";
import { Col, Row } from "antd";
import axiosConfig from "../../util/axiosConfig";
import { MetricsType } from "../../shared/interfaces/generic.interface";
import { formatToNaira } from "../../util/money";
import PageSpinner from "../../components/PageSpinner";
import requestErrorHandler from "../../util/requestErrorHandler";
import DashboardCard from "../../components/DashboardCard";
import MostRentedTrucks from "../../components/dashboard/MostRentedTrucks";

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [metrics, setMetrics] = useState<MetricsType>();
  const runOnce = useRef<any>();

  async function getDashboardMetrics() {
    setIsLoading(true);
    await axiosConfig
      .get("dashboard/admin")
      .then((response: any) => {
        setMetrics(response.data);
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    if (runOnce.current) return;
    getDashboardMetrics();
    runOnce.current = true;
  }, []);

  if (isLoading) {
    return <PageSpinner />;
  }

  return (
    <div>
      <div
        className="site-layout-background"
        style={{ padding: 8, minHeight: 360 }}
      >
        <h2 className="text-lg font-semibold">Dashboard</h2>

        <Row>
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <DashboardCard
              value1={formatToNaira(metrics?.totalRenterSpendingYearToDate!)}
              value2={formatToNaira(metrics?.totalRenterSpendingMonthly!)}
              value3={formatToNaira(metrics?.totalRenterSpending!)}
              title="Renter Transaction Value"
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <DashboardCard
              value1={formatToNaira(metrics?.totalTruckOwnerEarningYearToDate!)}
              value2={formatToNaira(metrics?.totalTruckOwnerEarningMonthly!)}
              value3={formatToNaira(metrics?.totalTruckOwnerEarning!)}
              title="Truck Owners Earning"
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <DashboardCard
              name1="Uncompleted"
              value1={metrics?.totalUncompletedRequest}
              name2="Completed"
              value2={metrics?.totalCompletedRequests}
              value3={metrics?.totalRequests}
              title="Rental Request"
            />
          </Col>

          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <DashboardCard
              value1={formatToNaira(metrics?.yearToDateCommisson!)}
              value2={formatToNaira(metrics?.monthlyComission!)}
              value3={formatToNaira(metrics?.totalComission!)}
              title="Commission Charges"
            />
          </Col>
        </Row>

        <Row justify="space-between" className="my-4">
          <Col className="gutter-row" xs={24} sm={24} md={12} lg={6} xl={6}>
            <DashboardCard
              name1="Active"
              value1={metrics?.totalNoOfActiveTrucks}
              name2="Inactive"
              value2={metrics?.totalNoOfInActiveTrucks}
              value3={metrics?.totalNoOfTrucks}
              title="Number of Trucks"
            />
          </Col>
          <Col className="gutter-row" xs={24} sm={24} md={12} lg={6} xl={6}>
            <DashboardCard
              name1="Active"
              value1={metrics?.noOfApprovedTruckOwners}
              name2="Suspend"
              value2={metrics?.noOfSuspendedTruckOwners}
              value3={metrics?.noOfTruckOwners}
              value4={metrics?.noOfPendingTruckOwners}
              name4="Pending"
              title="Truck Owners"
            />
          </Col>
          <Col className="gutter-row" xs={24} sm={24} md={12} lg={6} xl={6}>
            <DashboardCard
              name1="Active"
              value1={metrics?.noOfRenters}
              // value1={metrics?.totalApprovedRenters}
              name2="Suspend"
              value2={metrics?.totalSuspendedRenters}
              value3={metrics?.noOfRenters}
              title="Renters"
            />
          </Col>
          <Col className="gutter-row" xs={24} sm={24} md={12} lg={6} xl={6}>
            <DashboardCard
              name1="Active Keys"
              value1={metrics?.noOfPartners}
              // value1={metrics?.noOfActiveApiKey}
              name2="Revoke Keys"
              value2={metrics?.noOfRevokedApiKey}
              value3={metrics?.noOfPartners}
              name3="Partners"
              title="Partners"
            />
          </Col>
        </Row>

        <Row justify="space-between">
          <Col className="gutter-row" xs={24} sm={24} md={12} lg={6} xl={6}>
            <DashboardCard
              value1={metrics?.yearToDateOpex}
              value2={metrics?.monthlyOpex}
              value3={metrics?.totalOpex}
              title="OPEX"
            />
          </Col>
          <Col className="gutter-row" xs={24} sm={24} md={12} lg={6} xl={6}>
            <DashboardCard
              name1="Active"
              value1={metrics?.noOfAdmins}
              name2="Inactive"
              value2={0}
              value3={metrics?.noOfAdmins}
              title="Admin"
            />
          </Col>
          <Col
            className="gutter-row"
            xs={24}
            sm={24}
            md={12}
            lg={6}
            xl={6}
          ></Col>
          <Col
            className="gutter-row"
            xs={24}
            sm={24}
            md={12}
            lg={6}
            xl={6}
          ></Col>
        </Row>

        {/* <Row gutter={16} className="mt-4">
          <Col span={12}>
            <Card title="Most Rented Truck" bordered={false}>
              <TopRequests />
            </Card>
          </Col>
          <Col span={12}>
            <Card title="Top Keywords Search" bordered={false}>
              <TopSearch />
            </Card>
          </Col>
        </Row> */}

        <div className="my-4">{/* <MostRentedTrucks /> */}</div>
      </div>
    </div>
  );
};

export default Dashboard;
