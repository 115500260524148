import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import axiosConfig from "../util/axiosConfig";
import { useEffect, useLayoutEffect, useRef } from "react";

function ProtectedRoute({ children }: any) {
  let navigate = useNavigate();
  const { logIn, updateToken, logOut } = useAuth();
  const token = localStorage.getItem("admin-token-v1");
  const runOnce = useRef<any>();

  useEffect(() => {
    if (runOnce.current) return;
    getLoggedInUSer();
    runOnce.current = true;
  }, []);

  const getLoggedInUSer = async function () {
    try {
      if (token) {
        updateToken(token);
        await axiosConfig
          .get("/admin/logged-in")
          .then((response: any) => {
            logIn(response.data);
          })
          .catch((error) => {});
      } else {
        logOut();
        navigate("/login");
      }
    } catch (error) {
      logOut();
    }
  };

  useLayoutEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!token) {
    navigate("/login");
  }

  return children;
}

export default ProtectedRoute;
