import Login from "./auth/Login";
import NotFound from "./pages/NotFound";
import Dashboard from "./pages/admin/Dashboard";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import AdminLayout from "./components/AdminLayout";
import Renters from "./pages/admin/renters";
import Rentees from "./pages/admin/rentees";
import Categories from "./pages/admin/categories";
import GuestRoute from "./components/GuestRoute";
import Brands from "./pages/admin/brands";
import Commissions from "./pages/admin/commission";
import ShowCategory from "./pages/admin/categories/ShowCategory";
import Trucks from "./pages/admin/trucks";
import Zones from "./pages/admin/zones";
import ErrorBoundary from "./components/ErrorBoundary";
import Administrators from "./pages/admin/administrators";
import Materials from "./pages/admin/materials";
import ShowMaterial from "./pages/admin/materials/ShowMaterial";
import Partners from "./pages/admin/partners";
import Requests from "./pages/admin/requests";
import ShowTruck from "./pages/admin/trucks/ShowTruck";
import ShowRequest from "./pages/admin/requests/ShowRequest";
import ShowPartner from "./pages/admin/partners/ShowPartner";
import ShowCommission from "./pages/admin/commission/ShowCommission";
import ShowRenter from "./pages/admin/renters/ShowRenter";
import ShowRentees from "./pages/admin/rentees/ShowRentees";
import Enquiries from "./pages/admin/enquiries";
import ShowEnquiry from "./pages/admin/enquiries/show";
import Tracking from "./pages/admin/trucks/Tracking";

const Router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route
        element={
          <ProtectedRoute>
            <AdminLayout />
          </ProtectedRoute>
        }
        errorElement={<ErrorBoundary />}
      >
        <Route path="/" element={<Dashboard />} />
        <Route path="/admin" element={<Dashboard />} />
        <Route path="/admin/renters" element={<Renters />} />
        <Route path="/admin/renters/:renterId" element={<ShowRenter />} />
        <Route path="/admin/rentees" element={<Rentees />} />
        <Route path="/admin/rentees/:truckOwnerId" element={<ShowRentees />} />
        <Route path="/admin/partners" element={<Partners />} />
        <Route path="/admin/partners/:partnerId" element={<ShowPartner />} />
        <Route path="/admin/administrators" element={<Administrators />} />
        <Route path="/admin/categories" element={<Categories />} />
        <Route
          path="/admin/categories/:categoryId"
          element={<ShowCategory />}
        />
        <Route path="/admin/brands" element={<Brands />} />
        <Route path="/admin/commissions" element={<Commissions />} />
        <Route
          path="/admin/commissions/:commissionId"
          element={<ShowCommission />}
        />
        {/* <Route path="/admin/opex" element={<Opex />} /> */}
        <Route path="/admin/trucks" element={<Trucks />} />
        <Route path="/admin/trucks/:truckId" element={<ShowTruck />} />
        <Route path="/admin/trucks/tracking/:truckId" element={<Tracking />} />
        <Route path="/admin/zones" element={<Zones />} />
        <Route path="/admin/requests" element={<Requests />} />
        <Route path="/admin/requests/:requestId" element={<ShowRequest />} />
        <Route path="/admin/materials" element={<Materials />} />
        <Route path="/admin/materials/:materialId" element={<ShowMaterial />} />
        <Route path="/admin/enquiries/" element={<Enquiries />} />
        <Route path="/admin/enquiries/:enquiryId" element={<ShowEnquiry />} />
      </Route>
      <Route
        path="login"
        element={
          <GuestRoute>
            <Login />
          </GuestRoute>
        }
      />
      <Route path="trucks/tracking/:truckId" element={<Tracking />} />
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);

export default Router;
