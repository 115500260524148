import React, { useEffect, useState } from "react";
import { Breadcrumb, Dropdown, Skeleton, Space } from "antd";
import type { MenuProps } from "antd";
import axiosConfig from "../../../util/axiosConfig";
import { Link, useParams } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";
import { RentersType } from "../../../shared/interfaces/renters.interface";
import Table, { ColumnsType } from "antd/es/table";
import { useCallback } from "react";
import { OrdersType } from "../../../shared/interfaces/requests.interface";
import requestErrorHandler from "../../../util/requestErrorHandler";

const ShowRenter = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [page] = useState(1);
  const [paginationSize] = useState(10);
  const { renterId } = useParams();
  const [renter, setRenter] = useState<RentersType>();
  const [transactions, setTransactions] = useState<OrdersType[]>();
  const [status, setStatus] = useState("pending");

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
            setStatus("pending");
            getRentersOrderTransactions("pending");
          }}
        >
          Pending
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
            setStatus("processing");
            getRentersOrderTransactions("processing");
          }}
        >
          Processing
        </a>
      ),
    },
    {
      key: "3",
      label: (
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
            setStatus("completed");
            getRentersOrderTransactions("completed");
          }}
        >
          Completed
        </a>
      ),
    },
    {
      key: "4",
      label: (
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
            setStatus("cancelled");
            getRentersOrderTransactions("cancelled");
          }}
        >
          Cancelled
        </a>
      ),
    },
  ];

  const transactionColumns: ColumnsType<OrdersType> = [
    {
      title: "#",
      dataIndex: "id",
      render: (_, record, index) => (page - 1) * paginationSize + index + 1,
    },
    {
      title: "Rental ID",
      dataIndex: "rentalId",
      key: "rentalId",
      render: (_, record, index) => (
        <Link to={"/admin/requests/" + record.id}>
          {record.rentalId ?? record.orderId}
        </Link>
      ),
    },
    {
      title: "Rental Status",
      dataIndex: "orderStatus",
      key: "orderStatus",
      render: (_, record) => (
        <div className="capitalize">{record.orderStatus}</div>
      ),
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      render: (_, record) => (
        <div className="capitalize">{record.paymentStatus}</div>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (_, record) => (
        <div>
          {Number(record.price).toLocaleString("en-NG", {
            style: "currency",
            currency: "NGN",
          })}
        </div>
      ),
    },
  ];

  const getRenter = useCallback(async () => {
    setIsLoading(true);
    await axiosConfig
      .get("renters/get-one?renterId=" + renterId)
      .then((response: any) => {
        setRenter(response.data.renter);
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [renterId]);

  const getRentersOrderTransactions = useCallback(
    async (status: string) => {
      setIsLoading(true);
      await axiosConfig
        .get(
          `order/one-renter-order?status=${status}&paymentStatus=success&renterId=${renterId}`
        )
        .then((response: any) => {
          setTransactions(response.data.order);
        })
        .catch((error) => {
          requestErrorHandler(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [renterId]
  );

  useEffect(() => {
    getRenter();
    getRentersOrderTransactions(status);
  }, [getRenter, getRentersOrderTransactions, status]);

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="my-8">
          <h2 className="text-lg font-semibold">Renters</h2>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={"/admin"}>Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={"/admin/renters"}>Renters</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Renter</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        {/* <div>{formatToNaira(renter?.price ?? 0)}</div> */}
      </div>
      <div className="site-layout-background w-full overflow-auto">
        {isLoading ? (
          <Skeleton active paragraph={{ rows: 8 }} />
        ) : (
          <div>
            <div className="flex justify-between">
              <h4 className="text-lg font-semibold text-gray-600">
                Renter Information
              </h4>
            </div>
            <div className="flex items-center bg-white p-8 rounded-md">
              <table className="w-full">
                <tbody>
                  <tr className="w-full">
                    <td className="w-1/2 py-3">
                      <strong>Full Name: </strong>
                    </td>
                    <td className="w-1/2">
                      {renter?.firstName + " " + renter?.lastName}
                    </td>
                  </tr>
                  <tr className="w-full">
                    <td className="w-1/2 py-3">
                      <strong>Email: </strong>
                    </td>
                    <td className="w-1/2">{renter?.email}</td>
                  </tr>
                  <tr className="w-full">
                    <td className="w-1/2 py-3">
                      <strong>Address: </strong>
                    </td>
                    <td className="w-1/2">{renter?.businessAddress}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <h4 className="text-lg font-semibold text-gray-600">
              Renter Order Transactions
            </h4>
            <Dropdown.Button
              menu={{ items }}
              icon={<DownOutlined />}
              className="mb-2"
            >
              <Space className="capitalize">{status}</Space>
            </Dropdown.Button>
            <Table
              columns={transactionColumns}
              dataSource={transactions}
              rowKey="id"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ShowRenter;
