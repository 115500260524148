import { message } from "antd";
import axiosConfig from "../util/axiosConfig";
import requestErrorHandler from "../util/requestErrorHandler";

export async function getAllTrucks() {
  await axiosConfig
    .get("customer-trucks/get-all")
    .then((response: any) => {
      const trucks = response.data.data;
      return trucks;
    })
    .catch((error) => {
      requestErrorHandler(error);
    });
}

export async function truckApproval(id: any) {
  await axiosConfig
    .post(`/customer-trucks/approve?customerTruckId=${id}`)
    .then((response: any) => {
      message.success("Truck approved successfully");
    })
    .catch((error) => {
      requestErrorHandler(error);
    });
}

export async function truckRejection(id: any) {
  await axiosConfig
    .post(`/customer-trucks/reject?customerTruckId=${id}`)
    .then((response: any) => {
      message.success("Truck rejected successfully");
    })
    .catch((error) => {
      requestErrorHandler(error);
    });
}

export async function truckAvailability(id: any) {
  await axiosConfig
    .post(`/customer-trucks/toggle-truck?customerTruckId=${id}`)
    .then((response: any) => {
      message.success("Truck availability updated successfully");
    })
    .catch((error) => {
      requestErrorHandler(error);
    });
}

export async function truckDelete(id: any) {
  await axiosConfig
    .delete(`/customer-trucks/delete?truckId=${id}`)
    .then((response: any) => {
      message.success("Truck deleted successfully");
    })
    .catch((error) => {
      requestErrorHandler(error);
    });
}
