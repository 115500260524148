import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, message, Popconfirm, Skeleton } from "antd";
import axiosConfig from "../../../util/axiosConfig";
import { Link, useNavigate, useParams } from "react-router-dom";
import { formatToNaira } from "../../../util/money";
import {
  CommissionsDefault,
  CommissionsType,
} from "../../../shared/interfaces/commission.interface";
import EditCommission from "./EditCommission";
import { DeleteOutlined } from "@ant-design/icons";
import { useCallback } from "react";
import requestErrorHandler from "../../../util/requestErrorHandler";

const ShowCommission = () => {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { commissionId } = useParams();
  const [commission, setCommission] = useState<CommissionsType>({
    ...CommissionsDefault,
  });

  let getCommission = useCallback(
    async function getCommission() {
      setIsLoading(true);
      await axiosConfig
        .get("commission/get-one?commissionId=" + commissionId)
        .then((response: any) => {
          setCommission(response.data.data);
        })
        .catch((error) => {
          requestErrorHandler(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [commissionId]
  );

  async function handleDelete(id: any) {
    await axiosConfig
      .delete(`/commission/delete?commissionId=${id}`)
      .then((response: any) => {
        message.success("Commission deleted successfully");
        navigate("/admin/commissions");
      })
      .catch((error) => {
        requestErrorHandler(error);
      });
  }

  useEffect(() => {
    getCommission();
  }, [getCommission]);

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="my-8">
          <h2 className="text-lg font-semibold">Commissions</h2>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={"/admin"}>Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={"/admin/commissions"}>Commissions</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Commission</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <div className="site-layout-background w-full overflow-auto">
        {isLoading ? (
          <Skeleton active paragraph={{ rows: 8 }} />
        ) : (
          <div>
            <div className="flex justify-between">
              <h4 className="text-lg font-semibold text-gray-600">
                Commission Information
              </h4>
              <div className="flex gap-2">
                <EditCommission
                  commission={commission}
                  getCommissions={getCommission}
                />
                <Popconfirm
                  title={`Are you sure you want to delete ${commission?.commissionName} commission?`}
                  onConfirm={() => {
                    handleDelete(commission?.id);
                  }}
                  okText="Delete"
                  cancelText="Cancel"
                >
                  <Button type="primary" danger>
                    <DeleteOutlined />
                    <span className="">Delete</span>
                  </Button>
                </Popconfirm>
              </div>
            </div>
            <div className="flex items-center bg-white p-8 rounded-md">
              <table className="w-full">
                <tbody>
                  <tr className="w-full">
                    <td className="w-1/2 py-3">
                      <strong>Commission: </strong>
                    </td>
                    <td className="w-1/2">{commission?.commissionName}</td>
                  </tr>
                  <tr className="w-full">
                    <td className="w-1/2 py-3">
                      <strong>Description: </strong>
                    </td>
                    <td className="w-1/2">{commission?.description}</td>
                  </tr>
                  <tr className="w-full">
                    <td className="w-1/2 py-3">
                      <strong>Fee: </strong>
                    </td>
                    <td className="w-1/2">
                      {formatToNaira(commission?.processingFee)}
                    </td>
                  </tr>
                  <tr className="w-full">
                    <td className="w-1/2 py-3">
                      <strong>Rate: </strong>
                    </td>
                    <td className="w-1/2">
                      <span>{commission?.commissionRate ?? "0%"}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ShowCommission;
