import { DefaultMaterials, MaterialsType } from "./material.interface";
import { DefaultTrucks, TrucksType } from "./trucks.interface";

export interface OrdersType {
  id: string;
  totalPrice: number;
  orderType: string;
  renterId: string;
  rentalId: string;
  orderStatus: string;
  paymentStatus: boolean;
  OrderItems: OrderItem[];
  price: number;
  customerTruckId: string;
  truckOwnerId: string;
  pickUpPoint: string;
  deliveryPoint: string;
  trackingStatus: string;
  noOfTrips: number;
  dateDue: string;
  createdAt: string;
  updatedAt: string;
  materialId: string;
  orderId: string;
  customerTruck: TrucksType;
  material: MaterialsType;
  operatingExpense: number;
  currentTrip: number;
}

export interface OrderItem {
  id: string;
  price: number;
  customerTruckId: string;
  truckOwnerId: string;
  pickUpPoint: string;
  deliveryPoint: string;
  noOfTrips: number;
  dateDue: string;
  materialId: string;
  orderId: string;
  orderStatus: string;
  paymentStatus: boolean;
  trackingStatus: string;
  createdAt: string;
  updatedAt: string;
  customerTruck: TrucksType;
}

export interface InitiatedOrder {
  id: string;
  price: number;
  pickUpPoint: string;
  deliveryPoint: string;
  noOfTrips: number;
  partnerOrderId: string;
  partnerId: string;
  createdAt: string;
  updatedAt: string;
}

export const DefaultOrders = {
  id: "",
  totalPrice: 0,
  renterId: "",
  orderType: "",
  rentalId: "",
  orderStatus: "",
  paymentStatus: false,
  OrderItems: [],
  price: 0,
  customerTruckId: "",
  truckOwnerId: "",
  pickUpPoint: "",
  deliveryPoint: "",
  trackingStatus: "",
  noOfTrips: 0,
  dateDue: "",
  createdAt: "",
  updatedAt: "",
  materialId: "",
  orderId: "",
  customerTruck: DefaultTrucks,
  material: DefaultMaterials,
  operatingExpense: 0,
  currentTrip: 0,
};

export const defaultInitiatedOrder = {
  id: "",
  price: 0,
  pickUpPoint: "",
  deliveryPoint: "",
  noOfTrips: 0,
  partnerOrderId: "",
  partnerId: "",
  createdAt: "",
  updatedAt: "",
};
