import React, { useState } from "react";
import { Avatar, Button, Drawer, Form, Input, message, Upload } from "antd";
import { ArrowUpOutlined, EditOutlined } from "@ant-design/icons";
import axiosConfig from "../../../util/axiosConfig";
import uploadToS3 from "../../../util/uploadToS3";
import requestErrorHandler from "../../../util/requestErrorHandler";

const EditCategory = ({ category, getCategories }: any) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  async function handleSubmit() {
    setIsLoading(true);
    await axiosConfig
      .put(
        `/truck-category/update?truckId=${category.id}`,
        form.getFieldsValue()
      )
      .then((response: any) => {
        message.success("Category updated successfully");
        form.resetFields();
        getCategories();
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setOpen(false);
        setIsLoading(false);
      });
  }

  return (
    <>
      <Button
        type="primary"
        icon={<EditOutlined />}
        onClick={() => setOpen(true)}
        style={{ background: "orange", borderColor: "orange" }}
      >
        Edit
      </Button>
      <Drawer
        title="Edit Truck Category"
        placement="right"
        onClose={() => setOpen(false)}
        open={open}
      >
        <Form
          initialValues={category}
          className="w-100"
          onFinish={handleSubmit}
          form={form}
          layout="vertical"
        >
          <Form.Item
            name="truckName"
            label="Truck Name"
            rules={[{ required: true, message: "Please enter a valid name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="truckImage"
            label="Upload Category Image"
            valuePropName="file"
            getValueFromEvent={(e) => {
              const file = e.fileList[0];
              if (file?.status === "done") {
                return file.response;
              }
            }}
          >
            <Upload
              customRequest={uploadToS3}
              listType="picture"
              multiple={false}
              maxCount={1}
              accept="image/*"
            >
              <Avatar src={category.truckImage} />
              <Button icon={<ArrowUpOutlined />}>
                Click Here to upload an image
              </Button>
              <br />
              <small>
                Image should not be greater than <b>20MB</b>
              </small>
            </Upload>
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            block
            size="large"
            loading={isLoading}
            className="bg-[#096DD9]"
          >
            Save
          </Button>
        </Form>
      </Drawer>
    </>
  );
};

export default EditCategory;
