import React, { useCallback, useEffect, useState } from "react";
import { Breadcrumb, Skeleton } from "antd";
import axiosConfig from "../../../util/axiosConfig";
import { Link, useParams } from "react-router-dom";
import MapZone from "./MapZone";
import { ZonesType } from "../../../shared/interfaces/zone.interface";
import { MaterialsType } from "../../../shared/interfaces/material.interface";
import { TruckCategoryProfile } from "../../../shared/interfaces/category.interface";
import requestErrorHandler from "../../../util/requestErrorHandler";

const ShowMaterial = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { materialId } = useParams();
  const [zones, setZones] = useState<ZonesType[]>([]);
  const [truckProfiles, setTruckProfiles] = useState<TruckCategoryProfile[]>(
    []
  );
  const [material, setMaterial] = useState<MaterialsType>({
    id: "",
    name: "",
    mappedZones: [],
  });

  const getMaterial = useCallback(async function () {
    await axiosConfig
      .get("/materials/get-one?materialId=" + materialId)
      .then((response: any) => {
        const material = response.data.data;
        setMaterial(material);
      })
      .catch((error) => {
        requestErrorHandler(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getZones() {
    setIsLoading(true);
    await axiosConfig
      .get("zones/get-all")
      .then((response: any) => {
        const data = response.data.data;
        setZones(data);
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  async function getTruckProfiles() {
    setIsLoading(true);
    await axiosConfig
      .get("truck-category/get-allprofile")
      .then((response: any) => {
        const data = response.data.trucks;
        setTruckProfiles(data);
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getZones();
    getMaterial();
    getTruckProfiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMaterial]);

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="my-8">
          <h2 className="text-lg font-semibold">Map Zones</h2>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={"/admin"}>Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Map Zones</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        {/* <Button onClick={mapAll}>Map all</Button> */}
      </div>
      <div className="bg-white p-8">
        <h2 className="text-lg font-semibold">{material.name}</h2>
        <div className="site-layout-background w-full overflow-auto">
          {isLoading ? (
            <Skeleton active paragraph={{ rows: 8 }} />
          ) : (
            <div>
              <div className="table w-full">
                {[...zones].sort().map((pickupZone: ZonesType, index) => (
                  <div className="table-row" key={pickupZone.id}>
                    <div className="table-cell p-4 border border-gray-300 border-b-gray-300 border-solid">
                      {pickupZone.name}
                    </div>
                    {[...zones]
                      .sort()
                      .reverse()
                      .map((dropoffZone: ZonesType, index) => (
                        <MapZone
                          key={dropoffZone.id}
                          pickUpZone={pickupZone}
                          dropOffZone={dropoffZone}
                          material={material}
                          truckProfiles={truckProfiles}
                          getMaterial={getMaterial}
                        />
                      ))}
                  </div>
                ))}
                <div className="table-row">
                  <div className="table-cell p-4 border border-gray-300 border-b-gray-300 border-solid">
                    &nbsp;
                  </div>
                  {[...zones]
                    .sort()
                    .reverse()
                    .map((dZone, index) => (
                      <div
                        className="table-cell h-full border border-gray-300 border-b-gray-300 border-solid"
                        key={dZone.id}
                      >
                        <span className="w-full h-full p-4">{dZone.name}</span>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShowMaterial;
