import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  message,
  Popconfirm,
  Skeleton,
  Space,
  Table,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { DeleteOutlined } from "@ant-design/icons";
import axiosConfig from "../../../util/axiosConfig";
import EditZone from "./EditZone";
import AddZone from "./AddZone";
import { ZonesType } from "../../../shared/interfaces/zone.interface";
import { Link } from "react-router-dom";
import requestErrorHandler from "../../../util/requestErrorHandler";

const Zones = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [page] = React.useState(1);
  const [paginationSize] = useState(10);
  const [zones, setZones] = useState<ZonesType[]>([]);

  const columns: ColumnsType<ZonesType> = [
    {
      title: "#",
      dataIndex: "id",
      render: (_, record, index) => (page - 1) * paginationSize + index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Type",
      dataIndex: "zoneType",
      key: "zoneType",
      render: (_, record: ZonesType) => (
        <div className="capitalize">{record.zoneType}</div>
      ),
    },
    {
      title: "North East",
      dataIndex: "northEastBound",
      key: "northEastBound",
    },
    {
      title: "South West",
      dataIndex: "southWestBound",
      key: "southWestBound",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record: ZonesType) => (
        <Space size="middle">
          <EditZone zone={record} getZones={getZones} />
          <Popconfirm
            title={`Are you sure you want to delete ${record.name} zone?`}
            onConfirm={() => {
              handleDelete(record.id);
            }}
            okText="Delete"
            cancelText="Cancel"
          >
            <Button type="primary" danger>
              <DeleteOutlined />
              <span className="">Delete</span>
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  async function getZones() {
    setIsLoading(true);
    await axiosConfig
      .get("zones/get-all")
      .then((response: any) => {
        setZones(response.data.data);
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  async function handleDelete(id: any) {
    await axiosConfig
      .delete(`/zones/delete?zoneId=${id}`)
      .then((response: any) => {
        message.success("Zone delected successfully");
        getZones();
      })
      .catch((error) => {
        requestErrorHandler(error);
      });
  }

  useEffect(() => {
    getZones();
  }, []);

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="my-8">
          <h2 className="text-lg font-semibold">Zones</h2>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={"/admin"}>Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Zones</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div>
          <AddZone getZones={getZones} />
        </div>
      </div>
      <div className="site-layout-background w-full overflow-auto bg-white">
        {isLoading ? (
          <Skeleton active paragraph={{ rows: 8 }} />
        ) : (
          <Table columns={columns} dataSource={zones} rowKey="id" />
        )}
      </div>
    </div>
  );
};

export default Zones;
