import { Modal, Form, Input, Button, Select, message } from "antd";
import { useState, useEffect, useCallback } from "react";
import requestErrorHandler from "../util/requestErrorHandler";
import axiosConfig from "../util/axiosConfig";
import { TrucksType } from "../shared/interfaces/trucks.interface";

type Props = {
  open: boolean;
  onCancel: () => void;
  truckInfo: TrucksType;
  callback?: () => void;
};

const { Option } = Select;

export default function AddDevice({ open, onCancel, truckInfo }: Props) {
  const [form] = Form.useForm();

  const [devices, setDevices] = useState<[]>([]);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const addDevice = async (values: any) => {
    try {
      await axiosConfig.post(`eonsfleet/add-device-truck`, values);
      message.success("Device added successfully");
    } catch (error) {
      requestErrorHandler(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmit = async (values: any) => {
    setIsSubmitting(true);
    try {
      const { data }: { data: { message: string; device: TrackingDevice[] } } =
        await axiosConfig.get(
          `eonsfleet/get-device-withid?deviceUniqueId=${values.deviceUniqueId}`
        );
      if (data?.device?.length < 1) {
        message.error("Device does not exit on our platform");
        setIsSubmitting(false);
      } else {
        addDevice({ ...values, deviceObjectId: data.device[0].objectId });
      }
    } catch (error) {
      requestErrorHandler(error);
    }
  };

  return (
    <div key={truckInfo?.id} id={truckInfo?.id}>
      <Modal
        // key={truckInfo?.id}

        footer={null}
        open={open}
        title="Attach Device to Truck"
        onCancel={onCancel}
      >
        <Form
          key={truckInfo?.id}
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          // initialValues={truckInfo!}
        >
          <Form.Item
            rules={[
              { required: true, message: "Device unique Id cannot be blank" },
            ]}
            name="deviceUniqueId"
            label="Device unique Id"
          >
            <Input />
          </Form.Item>

          <Form.Item
            rules={[{ required: true, message: "Truck Tag cannot be blank" }]}
            name="truckId"
            label="Truck Tag"
            hidden
            initialValue={truckInfo?.id}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Button
              loading={isSubmitting}
              htmlType="submit"
              block
              type="primary"
              size="large"
            >
              Proceed
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
