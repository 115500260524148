import { Spin } from "antd";
import React from "react";
import styles from "./styles.module.css";
// import TruckLoading from "../../assets/truck-loading.json";
export default function PageSpinner({
  showSpinner,
}: {
  showSpinner?: boolean;
}) {
  return (
    <div className={styles.contianer}>
      {!showSpinner ? (
        <>
          <img
            src="/godump-favicon.png"
            alt="signal"
            width="50"
            className={styles.logo}
          />
          {/* <Lottie 
            animationData={TruckLoading}
            loop={true}
            width="300"
          /> */}
          {/* <img
            src="/images/truck-loading1.gif"
            alt="truck"
            width="200"
            // style={{ opacity: 0.5 }}
            // className={styles.logo}
          /> */}
        </>
      ) : (
        <Spin />
      )}
    </div>
  );
}
