import React, { useCallback, useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  message,
  Popconfirm,
  Skeleton,
  Space,
  Table,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { DeleteOutlined } from "@ant-design/icons";
import axiosConfig from "../../../util/axiosConfig";
import AddTruckProfile from "./AddTruckProfile";
import EditTruckProfile from "./EditTruckProfile";
import { Link, useParams } from "react-router-dom";
import {
  CategoryType,
  TruckCategoryProfile,
} from "../../../shared/interfaces/category.interface";
import requestErrorHandler from "../../../util/requestErrorHandler";

const ShowCategory = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [page] = React.useState(1);
  const [paginationSize] = useState(10);
  const { categoryId } = useParams();
  const [category, setCategory] = useState<CategoryType>({
    id: "",
    truckName: "",
    truckImage: "",
    truckCategoryProfile: [],
  });
  const [categoryProfiles, setCategoryProfiles] = useState<
    TruckCategoryProfile[]
  >([]);

  const columns: ColumnsType<TruckCategoryProfile> = [
    {
      title: "#",
      dataIndex: "type",
      render: (_, record, index) => (page - 1) * paginationSize + index + 1,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Tonnage",
      dataIndex: "tonnage",
      key: "tonnage",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record: TruckCategoryProfile) => (
        <Space size="middle">
          <EditTruckProfile
            truckProfile={record}
            category={category}
            getCategory={getCategory}
          />
          <Popconfirm
            title={`Are you sure you want to delete this truck category profile?`}
            onConfirm={() => {
              handleDelete(record.id);
            }}
            okText="Delete"
            cancelText="Cancel"
          >
            <Button type="primary" danger>
              <DeleteOutlined />
              <span className="">Delete</span>
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const getCategory = useCallback(async () => {
    setIsLoading(true);
    await axiosConfig
      .get("/truck-category/getone?truckId=" + categoryId)
      .then((response: any) => {
        const category = response.data.truck;
        setCategory(category);
        setCategoryProfiles(category.truckCategoryProfile);
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleDelete(id: any) {
    await axiosConfig
      .delete(`/truck-category/delete-profile?profileId=${id}`)
      .then((response: any) => {
        message.success("Truck Category Profile deleted successfully");
        getCategory();
      })
      .catch((error) => {
        requestErrorHandler(error);
      });
  }

  useEffect(() => {
    getCategory();
  }, [getCategory]);

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="my-8">
          <h2 className="text-lg font-semibold">Category Truck Profiles</h2>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={"/admin"}>Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Category</Breadcrumb.Item>
            <Breadcrumb.Item>Truck Profiles</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div>
          <AddTruckProfile getCategory={getCategory} category={category} />
        </div>
      </div>
      <div className="site-layout-background w-full overflow-auto bg-white">
        <div className="p-4 text-lg font-semibold">
          {category.truckName} Profiles
        </div>
        {isLoading ? (
          <Skeleton active paragraph={{ rows: 8 }} />
        ) : (
          <Table columns={columns} dataSource={categoryProfiles} />
        )}
      </div>
    </div>
  );
};

export default ShowCategory;
