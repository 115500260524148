import React, { useState } from "react";
import { Button, Drawer, Form, Input, message } from "antd";
import { EditOutlined } from "@ant-design/icons";
import axiosConfig from "../../../util/axiosConfig";
import requestErrorHandler from "../../../util/requestErrorHandler";

const EditAdministrator = ({ admin, getAdministrators }: any) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  async function handleSubmit() {
    setIsLoading(true);
    await axiosConfig
      .put(`/admin/update?adminId=${admin.id}`, form.getFieldsValue())
      .then((response: any) => {
        message.success("Admin updated successfully");
        form.resetFields();
        getAdministrators();
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setOpen(false);
        setIsLoading(false);
      });
  }

  return (
    <>
      <Button
        type="primary"
        icon={<EditOutlined />}
        onClick={() => setOpen(true)}
        style={{ background: "orange", borderColor: "orange" }}
      >
        Edit
      </Button>
      <Drawer
        title="Edit Truck Admin"
        placement="right"
        onClose={() => setOpen(false)}
        open={open}
      >
        <Form
          initialValues={admin}
          className="w-100"
          onFinish={handleSubmit}
          form={form}
          layout="vertical"
        >
          <Form.Item
            name="firstName"
            label="First name"
            rules={[{ required: true, message: "Enter a valid name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Last name"
            rules={[{ required: true, message: "Enter a valid name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="Emai"
            rules={[
              { required: true, message: "Enter a valid email" },
              { type: "email" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[{ required: false }]}
          >
            <Input.Password />
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            block
            size="large"
            loading={isLoading}
            className="bg-[#096DD9]"
          >
            Save
          </Button>
        </Form>
      </Drawer>
    </>
  );
};

export default EditAdministrator;
