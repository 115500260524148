import { Button, Form, Input, message, theme } from "antd";
import React, { useState } from "react";
import useAuth from "../hooks/useAuth";
import axios from "axios";
import Logo from "../components/Logo";
import { useNavigate } from "react-router-dom";
import requestErrorHandler from "../util/requestErrorHandler";

const Login = () => {
  let navigate = useNavigate();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const { logIn, user } = useAuth();
  const { useToken } = theme;
  const { token: themeToken } = useToken();

  async function handleSubmit({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) {
    setIsLoading(true);
    await axios
      .post(process.env.REACT_APP_BACKEND_URL + "/admin/login/", {
        email,
        password,
      })
      .then((response: any) => {
        const token = response.data.token;
        if (token) {
          axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
          axios
            .get(process.env.REACT_APP_BACKEND_URL + "/admin/logged-in")
            .then((response: any) => {
              const user = response.data;
              logIn(user, token);
              navigate("/admin");
            });
        } else {
          message.error("Unknown error! Try again!");
        }
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  if (user) navigate("/admin");

  return (
    <div className="font-sans text-gray-900 antialiased">
      <div className="min-h-screen flex flex-col justify-center items-center pt-6 sm:pt-0">
        <div className="w-full sm:max-w-md p-8 overflow-hidden shadow-md rounded-md">
          <div className="flex flex-col items-center justify-center">
            <Logo />
            <h4 className="font-bold text-xl pt-3 pb-6 my-4">
              Sign in to your account
            </h4>
          </div>
          <Form
            className="w-100"
            onFinish={handleSubmit}
            form={form}
            layout="vertical"
          >
            <Form.Item
              name="email"
              label="Email Address"
              rules={[{ required: true }, { type: "email" }]}
            >
              <Input autoComplete="email" />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
              style={{ margin: 0 }}
            >
              <Input.Password autoComplete="password" />
            </Form.Item>
            <div className="mb-4 w-full flex justify-end">
              <Button type="link">Forgot password?</Button>
            </div>
            <Button
              type="primary"
              loading={isLoading}
              size={"large"}
              className="w-full"
              style={{ backgroundColor: themeToken.colorPrimary }}
              htmlType="submit"
            >
              Sign In
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;
