import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Dropdown, Skeleton, Space } from "antd";
import type { MenuProps } from "antd";
import axiosConfig from "../../../util/axiosConfig";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  CloseCircleOutlined,
  DownOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { formatToNaira } from "../../../util/money";
import {
  PartnersPaymentRecord,
  PartnersType,
} from "../../../shared/interfaces/partners.interface";
import Table, { ColumnsType } from "antd/es/table";
import SetLimit from "./SetLimit";
import AddPayment from "./AddPayment";
import { useCallback } from "react";
import { OrdersType } from "../../../shared/interfaces/requests.interface";
import requestErrorHandler from "../../../util/requestErrorHandler";
import { parse } from "fast-qs";

const ShowPartner = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [page] = useState(1);
  const [paginationSize] = useState(10);
  const { partnerId } = useParams();
  const [partner, setPartner] = useState<PartnersType>();
  const [paymentRecord, setPaymentRecord] = useState<PartnersPaymentRecord[]>();
  const [transactions, setTransactions] = useState<OrdersType[]>();
  const [status, setStatus] = useState<string>("pending");
  const { search } = useLocation();

  const { fromRequest } = parse(search);

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
            setStatus("pending");
            getPartnersOrderTransactions("pending");
          }}
        >
          Pending
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
            setStatus("processing");
            getPartnersOrderTransactions("processing");
          }}
        >
          Processing
        </a>
      ),
    },
    {
      key: "3",
      label: (
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
            setStatus("completed");
            getPartnersOrderTransactions("completed");
          }}
        >
          Completed
        </a>
      ),
    },
    {
      key: "4",
      label: (
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
            setStatus("cancelled");
            getPartnersOrderTransactions("cancelled");
          }}
        >
          Cancelled
        </a>
      ),
    },
  ];

  const columns: ColumnsType<PartnersPaymentRecord> = [
    {
      title: "#",
      dataIndex: "id",
      render: (_, record, index) => (page - 1) * paginationSize + index + 1,
    },
    {
      title: "Amount Paid",
      dataIndex: "amountPaid",
      key: "amountPaid",
      render: (_, record) => <div>{formatToNaira(record.amountPaid)}</div>,
    },
    {
      title: "Amount Owed",
      dataIndex: "amountOwing",
      key: "amountOwing",
      render: (_, record) => <div>{formatToNaira(record.amountOwing)}</div>,
    },
  ];

  const transactionColumns: ColumnsType<OrdersType> = [
    {
      title: "#",
      dataIndex: "id",
      render: (_, record, index) => (page - 1) * paginationSize + index + 1,
    },
    {
      title: "Rental ID",
      dataIndex: "rentalId",
      key: "rentalId",
      render: (_, record, index) => (
        <Link to={"/admin/requests/" + record.id}>
          {record.rentalId ?? record.orderId}
        </Link>
      ),
    },
    {
      title: "Rental Status",
      dataIndex: "orderStatus",
      key: "orderStatus",
      render: (_, record) => (
        <div className="capitalize">{record.orderStatus}</div>
      ),
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      render: (_, record) => (
        <div className="capitalize">{record.paymentStatus}</div>
      ),
    },
    {
      title: "Price",
      dataIndex: "totalPrice",
      key: "totalPrice",
      render: (_, record) => (
        <div>
          {Number(record.totalPrice).toLocaleString("en-NG", {
            style: "currency",
            currency: "NGN",
          })}
        </div>
      ),
    },
  ];

  const getPartner = useCallback(async () => {
    setIsLoading(true);
    await axiosConfig
      .get("partner/get-one?partnerId=" + partnerId)
      .then((response: any) => {
        setPartner(response.data.data);
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [partnerId]);

  const getPaymentRecord = useCallback(async () => {
    setIsLoading(true);
    await axiosConfig
      .get("partner/record-by-partner?partnerId=" + partnerId)
      .then((response: any) => {
        setPaymentRecord(response.data.data);
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [partnerId]);

  const getPartnersOrderTransactions = useCallback(
    async (status: string) => {
      setIsLoading(true);
      await axiosConfig
        .get(
          `partner/orders?status=${status}&paymentStatus=success&partnerId=${partnerId}`
        )
        .then((response: any) => {
          setTransactions(response.data.order);
        })
        .catch((error) => {
          requestErrorHandler(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [partnerId]
  );

  async function regenerateAPIKey(id: any) {
    setIsLoading(true);
    await axiosConfig
      .post("partner/generate-new-api-key", { partnerId: id })
      .then((response: any) => {
        getPartner();
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  async function revokeAPIKey(id: any) {
    setIsLoading(true);
    await axiosConfig
      .post("partner/revoke-api-key", { partnerId: id })
      .then((response: any) => {
        getPartner();
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getPartner();
    getPaymentRecord();
    getPartnersOrderTransactions(status);
  }, [getPartner, getPaymentRecord, getPartnersOrderTransactions, status]);

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="my-8">
          <h2 className="text-lg font-semibold">Partners</h2>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={"/admin"}>Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={"/admin/partners"}>Partners</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Partner</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        {/* <div>{formatToNaira(partner?.price ?? 0)}</div> */}
      </div>
      <div className="site-layout-background w-full overflow-auto">
        {isLoading ? (
          <Skeleton active paragraph={{ rows: 8 }} />
        ) : (
          <div>
            <div className="flex justify-between">
              <h4 className="text-lg font-semibold text-gray-600">
                Partner Information
              </h4>
              <div className="flex gap-2">
                <Button type="primary">
                  <SetLimit partner={partner} getPartners={getPartner} />
                </Button>
                <Button type="primary">
                  <AddPayment partner={partner} getPartners={getPartner} />
                </Button>
              </div>
            </div>
            <div className="flex items-center bg-white p-8 rounded-md">
              <table className="w-full">
                <tbody>
                  <tr className="w-full">
                    <td className="w-1/2 py-3">
                      <strong>Businness Name: </strong>
                    </td>
                    <td className="w-1/2">{partner?.businessName}</td>
                  </tr>
                  <tr className="w-full">
                    <td className="w-1/2 py-3">
                      <strong>Email: </strong>
                    </td>
                    <td className="w-1/2">{partner?.email}</td>
                  </tr>
                  <tr className="w-full">
                    <td className="w-1/2 py-3">
                      <strong>Address: </strong>
                    </td>
                    <td className="w-1/2">{partner?.businessAddress}</td>
                  </tr>
                  <tr className="w-full">
                    <td className="w-1/2 py-3">
                      <strong>API Key: </strong>
                    </td>
                    <td className="w-1/2">
                      <span>{partner?.apiKey ?? "-------------------"}</span>
                      <span
                        className="ml-3 cursor-pointer text-blue-600"
                        onClick={() => regenerateAPIKey(partner?.id)}
                        title="Regenerate key"
                      >
                        <ReloadOutlined />
                      </span>
                      {partner?.apiKey && (
                        <span
                          className="ml-3 cursor-pointer text-red-600"
                          onClick={() => revokeAPIKey(partner?.id)}
                          title="Revoke key"
                        >
                          <CloseCircleOutlined />
                        </span>
                      )}
                    </td>
                  </tr>
                  <tr className="w-full">
                    <td className="w-1/2 py-3">
                      <strong>Amount Owed: </strong>
                    </td>
                    <td className="w-1/2">
                      {formatToNaira(partner?.totalAmountOwed ?? 0)}
                    </td>
                  </tr>
                  <tr className="w-full">
                    <td className="w-1/2 py-3">
                      <strong>Amount Paid: </strong>
                    </td>
                    <td className="w-1/2">
                      {formatToNaira(partner?.totalAmountPaid ?? 0)}
                    </td>
                  </tr>
                  <tr className="w-full">
                    <td className="w-1/2 py-3">
                      <strong>Limit: </strong>
                    </td>
                    <td className="w-1/2">
                      {formatToNaira(partner?.limit ?? 0)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {fromRequest !== "true" && (
              <>
                <h4 className="text-lg font-semibold text-gray-600">
                  Partner Payment Record
                </h4>
                <Table
                  columns={columns}
                  dataSource={paymentRecord}
                  rowKey="id"
                />

                <h4 className="text-lg font-semibold text-gray-600">
                  Partner Order Transactions
                </h4>

                <Dropdown.Button
                  menu={{ items }}
                  icon={<DownOutlined />}
                  className="mb-2"
                >
                  <Space className="capitalize">{status}</Space>
                </Dropdown.Button>
                <Table
                  columns={transactionColumns}
                  dataSource={transactions}
                  rowKey="id"
                />
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ShowPartner;
