
function NotFound() {
  return (
    <div>
      <div>
        <h2 className="text-5xl font-bold text-gray-600">NotFound!</h2>
      </div>
    </div>
  );
}

export default NotFound;
