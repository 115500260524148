import React, { useEffect, useState } from "react";
import {
  Avatar,
  Breadcrumb,
  Dropdown,
  Menu,
  Skeleton,
  Space,
  Table,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { DownOutlined } from "@ant-design/icons";
import axiosConfig from "../../../util/axiosConfig";
import { TrucksType } from "../../../shared/interfaces/trucks.interface";
import { Link } from "react-router-dom";
import {
  truckApproval,
  truckAvailability,
  truckRejection,
} from "../../../services/truckService";
import requestErrorHandler from "../../../util/requestErrorHandler";
import AddTruck from "./AddTruck";

const Trucks = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [page] = React.useState(1);
  const [paginationSize] = useState(10);
  const [trucks, setTrucks] = useState([]);

  const columns: ColumnsType<TrucksType> = [
    {
      title: "#",
      dataIndex: "id",
      render: (_, record, index) => (page - 1) * paginationSize + index + 1,
    },
    {
      title: "Truck Owner Name",
      dataIndex: "truckOwner",
      key: "name",
      render: (_, record) => (
        <Link to={"/admin/trucks/" + record.id}>{_?.businessName}</Link>
      ),
    },
    {
      title: "Tonnage",
      dataIndex: "tonnage",
      key: "tonnage",
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "Age",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "Head Color",
      dataIndex: "headColor",
      key: "headColor",
    },
    {
      title: "Bucket Color",
      dataIndex: "bucketColor",
      key: "bucketColor",
    },
    {
      title: "Plate Number",
      dataIndex: "plateNumber",
      key: "plateNumber",
    },
    {
      title: "Picture",
      dataIndex: "picture",
      key: "picture",
      render: (_, record, index) => (
        <Avatar shape="square" size={50} src={record.picture} />
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Available",
      dataIndex: "isAvailable",
      key: "isAvailable",
      render: (_, record, index) => (
        <div>{record.isAvailable ? "Yes" : "No"}</div>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          {/* <EditTruck truck={record} getTrucks={getTrucks} /> */}

          <Dropdown
            trigger={["click"]}
            overlay={
              <Menu>
                {record.status !== "approved" && (
                  <Menu.Item
                    key="0"
                    onClick={() => {
                      handleApproval(record.id);
                    }}
                  >
                    Approve
                  </Menu.Item>
                )}
                {record.status === "approved" && (
                  <Menu.Item
                    key="1"
                    onClick={() => {
                      handleRejection(record.id);
                    }}
                  >
                    Suspend
                  </Menu.Item>
                )}
                <Menu.Item
                  key="2"
                  onClick={() => {
                    handleAvailability(record.id);
                  }}
                >
                  {record.isAvailable ? "Disable" : "Enable"}
                </Menu.Item>
              </Menu>
            }
          >
            <Space className="cursor-pointer">
              More
              <DownOutlined />
            </Space>
          </Dropdown>
        </Space>
      ),
    },
  ];

  async function getTrucks() {
    setIsLoading(true);
    await axiosConfig
      .get("customer-trucks/get-all")
      .then((response: any) => {
        setTrucks(response.data.data);
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  async function handleApproval(id: string) {
    await truckApproval(id);
    getTrucks();
  }

  async function handleAvailability(id: string) {
    await truckAvailability(id);
    getTrucks();
  }

  async function handleRejection(id: string) {
    await truckRejection(id);
    getTrucks();
  }

  useEffect(() => {
    getTrucks();
  }, []);

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="my-8">
          <h2 className="text-lg font-semibold">Trucks</h2>
        </div>

        <div>
          <AddTruck getTrucks={getTrucks} />
        </div>
      </div>
      <div className="site-layout-background w-full overflow-auto bg-white">
        {isLoading ? (
          <Skeleton active paragraph={{ rows: 8 }} />
        ) : (
          <Table columns={columns} dataSource={trucks} rowKey="id" />
        )}
      </div>
    </div>
  );
};

export default Trucks;
