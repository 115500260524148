import { useEffect, useState } from "react";

const useLoadScript = () => {
  const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = GOOGLE_API_KEY!;
    script.async = true;

    script.onload = () => setLoaded(true);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return loaded;
};

export default useLoadScript;
