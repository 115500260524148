import { Alert, Button, message } from "antd";
import axiosConfig from "../util/axiosConfig";
import React, { useState } from "react";
import requestErrorHandler from "../util/requestErrorHandler";

const TrackingButton = ({
  trackingStatus,
  orderRequestId,
  callback,
}: {
  trackingStatus: string;
  orderRequestId: string;
  callback?: () => void;
}) => {
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const handleStatus = async (status: string) => {
    setIsUpdating(true);

    try {
      await axiosConfig.put("order/tracking-status", {
        trackingStatus: status,
        orderRequestId,
      });
      message.success("Tracking status Updated");
      callback?.();
    } catch (error) {
      requestErrorHandler(error);
      // requesErrorHandler(error);
    } finally {
      setIsUpdating(false);
    }
  };

  switch (trackingStatus) {
    case "ongoing":
      return (
        <Button
          type="primary"
          size="large"
          loading={isUpdating}
          onClick={() => handleStatus("delivered")}
        >
          SET AS DELIVERED{" "}
        </Button>
      );
    case "delivered":
      return <Alert message="Order Delivered" />;
    default:
      return (
        <Button
          type="primary"
          size="large"
          loading={isUpdating}
          onClick={() => handleStatus("ongoing")}
        >
          START TRIP
        </Button>
      );
  }
};

export default TrackingButton;
