import React, { useEffect, useState } from "react";
import {
  Avatar,
  Breadcrumb,
  Button,
  message,
  Popconfirm,
  Skeleton,
  Space,
  Table,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { DeleteOutlined } from "@ant-design/icons";
import AddBrand from "./AddBrand";
import EditBrand from "./EditBrand";
import axiosConfig from "../../../util/axiosConfig";
import { BrandsType } from "../../../shared/interfaces/brand.interface";
import { Link } from "react-router-dom";
import requestErrorHandler from "../../../util/requestErrorHandler";

const Brands = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [page] = React.useState(1);
  const [paginationSize] = useState(10);
  const [brands, setBrands] = useState([]);

  const columns: ColumnsType<BrandsType> = [
    {
      title: "#",
      dataIndex: "id",
      render: (_, record, index) => (page - 1) * paginationSize + index + 1,
    },
    {
      title: "Brand Logo",
      dataIndex: "brandLogo",
      key: "brandLogo",
      render: (_, record, index) => (
        <Avatar shape="square" size={50} src={record.brandLogo} />
      ),
    },
    {
      title: "Brand Name",
      dataIndex: "brandName",
      key: "brandName",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <EditBrand brand={record} getBrands={getBrands} />
          <Popconfirm
            title={`Are you sure you want to delete ${record.brandName} brand?`}
            onConfirm={() => {
              handleDelete(record.id);
            }}
            okText="Delete"
            cancelText="Cancel"
          >
            <Button type="primary" danger>
              <DeleteOutlined />
              <span className="">Delete</span>
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  async function getBrands() {
    setIsLoading(true);
    await axiosConfig
      .get("brand/getall")
      .then((response: any) => {
        setBrands(response.data.data);
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  async function handleDelete(id: any) {
    await axiosConfig
      .delete(`/brand/delete?brandId=${id}`)
      .then((response: any) => {
        message.success("Brand deleted successfully");
        getBrands();
      })
      .catch((error) => {
        requestErrorHandler(error);
      });
  }

  useEffect(() => {
    getBrands();
  }, []);

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="my-8">
          <h2 className="text-lg font-semibold">Brands</h2>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={"/admin"}>Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Brands</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div>
          <AddBrand getBrands={getBrands} />
        </div>
      </div>
      <div className="site-layout-background w-full overflow-auto bg-white">
        {isLoading ? (
          <Skeleton active paragraph={{ rows: 8 }} />
        ) : (
          <Table columns={columns} dataSource={brands} rowKey="id" />
        )}
      </div>
    </div>
  );
};

export default Brands;
