import React from "react";
import truck from "../../assets/3d-truck.png";

export default function TruckMarker({ rotate }: { rotate: number }) {
  return (
    <div style={{ transition: "0.5s", rotate: `${+rotate - 120}deg` }}>
      <img src={truck} alt="" width={30} />
    </div>
  );
}
