import { useState } from "react";
import TextArea from "antd/es/input/TextArea";
import axiosConfig from "../../../util/axiosConfig";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  AutoComplete,
  Button,
  Drawer,
  Form,
  Input,
  message,
  Select,
} from "antd";
import requestErrorHandler from "../../../util/requestErrorHandler";

const AddZone = ({ getZones }: any) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    []
  );
  const [form] = Form.useForm();

  const { clearSuggestions } = usePlacesAutocomplete();

  async function handleSubmit() {
    setIsLoading(true);
    await axiosConfig
      .post("/zones/create", form.getFieldsValue())
      .then((response: any) => {
        message.success("Zone added successfully");
        form.resetFields();
        setOpen(false);
        getZones();
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setIsLoading(true);
      });
    setIsLoading(false);
  }

  const handleSearch = (value: string) => {
    let res: { value: string; label: string }[] = [];
    if (!value || value.indexOf("@") >= 0) {
      res = [];
    } else {
      res = ["gmail.com", "163.com", "qq.com"].map((domain) => ({
        value,
        label: `${value}@${domain}`,
      }));
    }
    setOptions(res);
  };

  // async function handleSearch(value: string) {
  //   setValue(value);

  //   const optionSet: { value: string }[] = [];

  //   data.map((place: any) =>
  //     optionSet.push({ value: place.description })
  //   );
  //   setOptions(optionSet);

  // }

  async function handleSelect(value: string, field: any) {
    const parameter = {
      address: value,
    };

    getGeocode(parameter)
      .then((results: any) => {
        const { lat, lng } = getLatLng(results[0]);

        form.setFieldValue(field, lat + ", " + lng);
      })
      .catch((error: any) => {})
      .finally(() => clearSuggestions());
  }

  async function handleGeolocate(value: string, field: any) {
    const geocoder = new google.maps.Geocoder();
    const latlngStr = value.split(",", 2);
    const latlng = {
      lat: parseFloat(latlngStr[0]),
      lng: parseFloat(latlngStr[1]),
    };
    geocoder
      .geocode({ location: latlng })
      .then((response: any) => {
        if (response.results[0]) {
          const address = response.results[0].formatted_address;
          form.setFieldValue(field, address);
        } else {
        }
      })
      .catch((e: any) => {});
  }

  return (
    <>
      <Button
        type="primary"
        className="bg-[#096DD9]"
        onClick={() => setOpen(true)}
      >
        Add Zone
      </Button>
      <Drawer
        title="Add Zone"
        placement="right"
        onClose={() => {
          setOpen(false);
          form.resetFields();
          setIsLoading(false);
        }}
        open={open}
      >
        <Form
          className="w-100"
          onFinish={handleSubmit}
          form={form}
          layout="vertical"
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: "Enter a valid name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="zoneType"
            label="Zone Type"
            rules={[{ required: true, message: "Select a valid zone type" }]}
          >
            <Select
              placeholder="Select"
              options={[
                {
                  value: "standard",
                  label: "Standard",
                },
                {
                  value: "quarry",
                  label: "Quarry",
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true }]}
          >
            <TextArea />
          </Form.Item>
          <Form.Item
            name="northEastAddress"
            label="North East Address"
            rules={[{ required: true, message: "Enter a valid address" }]}
          >
            <AutoComplete
              onSearch={handleSearch}
              onSelect={(enterValue: string) => {
                handleSelect(enterValue, "northEastBound");
              }}
              placeholder="Search place"
              options={options}
            />
          </Form.Item>
          <Form.Item
            name="northEastBound"
            label="North East Bound"
            rules={[{ required: true, message: "Enter a valid bound" }]}
          >
            <Input
              placeholder="7.3338, 7.89009"
              onChange={(e) => {
                handleGeolocate(e.target.value, "northEastAddress");
              }}
            />
          </Form.Item>
          <Form.Item
            name="southWestAddress"
            label="South West Address"
            rules={[{ required: true, message: "Enter a valid address" }]}
          >
            <AutoComplete
              onSearch={handleSearch}
              onSelect={(enterValue: string) => {
                handleSelect(enterValue, "southWestBound");
              }}
              placeholder="Search place"
              options={options}
            />
          </Form.Item>
          <Form.Item
            name="southWestBound"
            label="South West Bound"
            rules={[{ required: true, message: "Enter a valid bound" }]}
          >
            <Input
              placeholder="7.3338, 7.89009"
              onChange={(e) => {
                handleGeolocate(e.target.value, "southWestAddress");
              }}
            />
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            block
            size="large"
            loading={isLoading}
            className="bg-[#096DD9]"
          >
            Save
          </Button>
        </Form>
      </Drawer>
    </>
  );
};

export default AddZone;
