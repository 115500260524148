import { Card } from "antd";

const DashboardCard = ({
  name1,
  value1,
  name2,
  value2,
  name3,
  value3,
  title,
  value4,
  name4,
}: any) => {
  return (
    <Card style={{ width: 290 }}>
      <h4>{title}</h4>
      <div className="flex justify-between">
        <div className="space-y-2 w-full">
          <div className="flex justify-between">
            <span className="name w-1/2">{name1 ?? "Year to date"}:</span>
            <span className="value w-1/2">{value1 ?? 0}</span>
          </div>
          <div className="flex justify-between">
            <span className="name w-1/2">{name2 ?? "Monthly"}:</span>
            <span className="value w-1/2">{value2 ?? 0}</span>
          </div>
          <div className="flex justify-between">
            <span className="name w-1/2">{name3 ?? "Total"}:</span>
            <span className="value w-1/2">{value3 ?? 0}</span>
          </div>
        </div>
        {value4 ? (
          <div className="w-1/2">
            <div className="flex justify-between">
              <span className="name">{name4 ?? ""}:</span>
              <span className="value">{value4 ?? 0}</span>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </Card>
  );
};

export default DashboardCard;
