import { SyncOutlined } from "@ant-design/icons";

function Spinner() {
  return (
    <div className="bg-white w-full h-full min-h-screen flex items-center justify-center">
      <div className="text-center">
        <SyncOutlined spin style={{ fontSize: 30 }} />
        {/* <LoadingOutlined style={{ fontSize: 20 }} /> */}
        {/* <div className="text-xs text-ellipsis italic font-thin">Loading</div> */}
      </div>
    </div>
  );
}

export default Spinner;
