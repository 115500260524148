import { useEffect, useState } from "react";
import {
  Button,
  Drawer,
  Form,
  Input,
  InputNumber,
  message,
  Select,
  Switch,
} from "antd";
import axiosConfig from "../../../util/axiosConfig";
import TextArea from "antd/es/input/TextArea";
import { RenteesType } from "../../../shared/interfaces/truckowners.interface";
import { CommissionsType } from "../../../shared/interfaces/commission.interface";
import { EditOutlined } from "@ant-design/icons";
import { useCallback } from "react";
import requestErrorHandler from "../../../util/requestErrorHandler";

const EditCommission = ({
  commission,
  getCommissions,
}: {
  commission: CommissionsType;
  getCommissions: any;
}) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [commissionRate, setCommissionRate] = useState(false);
  const [processingFee, setProcessingFee] = useState(false);
  const [isGeneral, setIsGeneral] = useState(commission.isGeneral);
  const [truckOwners, setTruckOwners] = useState<RenteesType[]>([]);
  const [defaultTruckOwners, setDefaultTruckOwners] = useState<object[]>([]);
  const [form] = Form.useForm();

  async function handleSubmit() {
    let truckOwners = form.getFieldValue("truckOwners");
    let ids = truckOwners.map((truckOwnerId: string) => ({
      truckOwnerId: truckOwnerId,
    }));
    try {
      await axiosConfig
        .put(`/commission/update?commissionId=${commission.id}`, {
          ...form.getFieldsValue(),
          truckOwners: ids,
        })
        .then((response: any) => {
          message.success("Commission updated successfully");
          form.resetFields();
          setOpen(false);
          setIsGeneral(true);
          setProcessingFee(false);
          setCommissionRate(false);
          getCommissions();
        })
        .catch((error) => {
          requestErrorHandler(error);
        });
    } catch (error: any) {
      requestErrorHandler(error);
    }
  }

  const getRentees = useCallback(async () => {
    setIsLoading(true);
    await axiosConfig
      .get("truck-owners/get-all")
      .then((response: any) => {
        setTruckOwners(response.data.truckOwners);
        let dfto: object[] = [];
        truckOwners.map((truckOwner: any) => {
          if (commission.truckOwners) {
            let tro = commission.truckOwners.map(
              (to) => to.truckOwnerId.truckOwnerId
            );
            if (tro.includes(truckOwner.id)) {
              dfto.push({
                label: truckOwner.businessName,
                value: truckOwner.id,
              });
            }
          }
          return dfto;
        });
        setDefaultTruckOwners(dfto);
      })
      .catch((error) => {
        requestErrorHandler(error);
      });

    setIsLoading(false);
  }, [commission, truckOwners]);

  useEffect(() => {
    getRentees();
    form.validateFields(["processingFee", "commissionRate"]);
    if (commission.commissionRate > 0) {
      setCommissionRate(true);
      setProcessingFee(false);
    } else if (commission.processingFee > 0) {
      setProcessingFee(true);
      setCommissionRate(false);
    } else {
      setProcessingFee(false);
      setCommissionRate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  return (
    <>
      <Button
        type="primary"
        icon={<EditOutlined />}
        onClick={() => setOpen(true)}
        style={{ background: "orange", borderColor: "orange" }}
      >
        Edit
      </Button>
      <Drawer
        title="Edit Commission"
        placement="right"
        onClose={() => setOpen(false)}
        open={open}
      >
        <Form
          className="w-100"
          onFinish={handleSubmit}
          initialValues={commission}
          form={form}
          layout="vertical"
        >
          <Form.Item
            name="commissionName"
            label="Commission name"
            rules={[{ required: true, message: "Enter a valid name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true }]}
          >
            <TextArea />
          </Form.Item>
          <Form.Item
            name="commissionRate"
            label="Commission Rate"
            rules={[
              { required: commissionRate, message: "Enter a commission rate" },
              // { type: "number" },
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              onChange={(value: any) => {
                if (value > 0) {
                  setCommissionRate(true);
                  setProcessingFee(false);
                } else {
                  setProcessingFee(false);
                  setCommissionRate(false);
                }
              }}
              disabled={processingFee}
            />
          </Form.Item>
          <Form.Item
            name="processingFee"
            label="Processing fees"
            rules={[
              { required: processingFee, message: "Enter a processing fees" },
              { type: "number" },
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              onChange={(value: any) => {
                if (value > 0) {
                  setProcessingFee(true);
                  setCommissionRate(false);
                } else {
                  setProcessingFee(false);
                  setCommissionRate(false);
                }
              }}
              disabled={commissionRate}
            />
          </Form.Item>
          <Form.Item
            name="isGeneral"
            label="General"
            rules={[{ required: true }]}
          >
            <Switch
              defaultChecked={commission.isGeneral}
              onChange={(value: boolean) => {
                setIsGeneral(value);
                value && form.setFieldValue("truckOwners", []);
              }}
            />
          </Form.Item>
          {!isGeneral ? (
            <Form.Item name="_truckOwners" label="Truck owners">
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                placeholder="Select"
                defaultValue={defaultTruckOwners}
                optionLabelProp="businessName"
                options={truckOwners}
                fieldNames={{ label: "businessName", value: "id" }}
              />
            </Form.Item>
          ) : null}
          <Button
            type="primary"
            htmlType="submit"
            block
            size="large"
            loading={isLoading}
            className="bg-[#096DD9]"
          >
            Save
          </Button>
        </Form>
      </Drawer>
    </>
  );
};

export default EditCommission;
