import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import axiosConfig from "../util/axiosConfig";
import { useEffect, useLayoutEffect } from "react";

function GuestRoute({ children }: any) {
  let navigate = useNavigate();
  const { logIn, updateToken, logOut } = useAuth();
  const token = localStorage.getItem("admin-token-v1");

  useLayoutEffect(() => {
    (async function () {
      try {
        if (token) {
          updateToken(token);
          await axiosConfig
            .get("/admin/logged-in")
            .then((response: any) => {
              logIn(response.data, token);
              navigate("/admin");
            })
            .catch((error) => {});
        }
      } catch (error) {
        logOut();
      }
    })();
  });

  return children;
}

export default GuestRoute;
