import { message } from "antd";

export default function requestErrorHandler(error: any) {
  if (error.response) {
    const data = error.response.data;
    message.error({
      content: data?.error || (
        <>
          Apologies. Our system is currently experiencing downtime. Please feel
          free to contact us via email at{" "}
          <a href="mailto:info@godump.co"> info@materialspro.ng</a> or by phone
          at <a href="tel:09165407978"> 09165407978</a> for further assistance
          or inquiries.,
        </>
      ),
      style: {
        textTransform: data?.error ? "capitalize" : "none",
        width: 400,
        marginInline: "auto",
      },
      duration: data?.error ? 3 : 15,
    });
  }
}
