import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  open: false,
}

const slice = createSlice({
  name: "sidenav",
  initialState,
  reducers: {
    open(state) {
      state.open = true
    },
    close(state) {
      state.open = false
    },
    toggle(state) {
      state.open = !state.open
    },
  },
})

export default slice.reducer

export const { open, close, toggle } = slice.actions
