import axios from "axios";
const token = localStorage.getItem("admin-token-v1");

const axiosConfig = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    common: { Authorization: `Bearer ${token}` },
  },
});

axiosConfig.interceptors.request.use(
  function (config: any) {
    const token = localStorage.getItem("admin-token-v1");
    // const {token}= useAuth()
    // if (!token) return Promise.reject("unAuthorized");
    const headers: any = {
      Authorization: "Bearer " + token,
    };
    config.headers = headers!;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axiosConfig.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (
      error.response.status === 401 ||
      error.response?.data?.error === "Not authorized, token failed"
    ) {
      window.location.href = "/login";
    }

    return Promise.reject(error);
  }
);

export default axiosConfig;
