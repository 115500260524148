function ErrorBoundary() {
  return (
    <div className="bg-white w-full h-full min-h-screen flex items-center justify-center">
      <div className="text-center">
        <h2 className="text-2xl font-bold">Oops!</h2>
        <h4 className="text-lg font-semibold">Server error...</h4>
      </div>
    </div>
  );
}

export default ErrorBoundary;
