import {
  Form,
  Input,
  Button,
  Upload,
  Select,
  Row,
  Col,
  Alert,
  Drawer,
  message,
  Avatar,
} from "antd";
import {
  ArrowUpOutlined,
  EditOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useState, useEffect } from "react";
import { ZonesType } from "../../../shared/interfaces/zone.interface";
import { BrandsType } from "../../../shared/interfaces/brand.interface";
import { TruckCategoryProfile } from "../../../shared/interfaces/category.interface";
import axiosConfig from "../../../util/axiosConfig";
import uploadToS3 from "../../../util/uploadToS3";
import { TruckersType } from "../../../shared/interfaces/truckowners.interface";
import { TrucksType } from "../../../shared/interfaces/trucks.interface";
import requestErrorHandler from "../../../util/requestErrorHandler";

const { Option } = Select;

export const handleMultiSelect = (e: any, type?: "material") => {
  return [
    ...e.map((value: any) =>
      type === "material"
        ? {
            materialId: value.split("&")[0],
            materialName: value.split("&")[1],
          }
        : {
            id: value.split("&")[0],
            name: value.split("&")[1],
            description: value.split("&")[2],
          }
    ),
  ];
};

export default function EditTruck({
  truck,
  getTrucks,
}: {
  truck: TrucksType;
  getTrucks: any;
}) {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();

  const [tonnage] = useState<number | undefined>();
  // const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [zones, setZones] = useState<ZonesType[]>();
  const [materials, setMaterials] = useState<any[]>();
  const [brands, setBrands] = useState<BrandsType[]>();
  const [truckProfile, setTruckProfile] = useState<TruckCategoryProfile[]>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [, setTruckOwners] = useState<TruckersType[]>([]);

  async function handleSubmit() {
    setLoadingData(true);
    setIsSubmitting(true);

    const values = form.getFieldsValue();

    const payload = {
      ...values,
      serviceLocation: values.serviceLocation?.map((servLoc: string) => {
        const info = servLoc.split("&");
        return {
          id: info[0],
          name: info[1],
          description: info[2],
        };
      }),
      materials: values.materials?.map((mat: string) => {
        const info = mat.split("&");

        return {
          materialId: info[0],
          materialName: info[1],
        };
      }),
    };

    await axiosConfig
      .put(`/customer-trucks/update?truckId=${truck.id}`, payload)
      .then((response: any) => {
        message.success("Truck added successfully");
        form.resetFields();
        setOpen(false);
        getTrucks();
      })
      .catch((error) => {
        requestErrorHandler(error);
      });
    setLoadingData(false);
    setIsSubmitting(false);
  }

  const getBrands = async () => {
    await axiosConfig
      .get("brand/getall")
      .then((res) => setBrands(res.data.data))
      .catch((error) => requestErrorHandler(error));
  };
  const getZones = async () => {
    await axiosConfig
      .get("zones/get-all")
      .then((res) => setZones(res.data.data))
      .catch((error) => requestErrorHandler(error));
  };
  const getTruckProfiles = async () => {
    await axiosConfig
      .get("truck-category/get-allprofile")
      .then((res) => setTruckProfile(res.data.trucks))
      .catch((error) => requestErrorHandler(error));
  };
  const getMaterials = async () => {
    await axiosConfig
      .get("materials/get-all")
      .then((res) => {
        setMaterials(res.data.data);
      })
      .catch((error) => requestErrorHandler(error));
  };

  async function getTruckers() {
    await axiosConfig
      .get("truck-owners/get-all")
      .then((response: any) => {
        setTruckOwners(response.data.truckOwners);
      })
      .catch((error) => {
        requestErrorHandler(error);
      });
  }

  useEffect(() => {
    setLoadingData(true);
    Promise.all([
      getTruckProfiles(),
      getBrands(),
      getZones(),
      getMaterials(),
      getTruckers(),
    ])
      .catch((error) => setHasError(true))
      .finally(() => setLoadingData(false));
  }, []);

  const defaultZones = truck.serviceLocation
    ? truck?.serviceLocation?.map(
        (item) => item.id + "&" + item.name + "&" + item.description
      )
    : [];
  const defaultMaterials = truck?.materials?.map(
    (mat) => mat.materialId + "&" + mat.materialName
  );

  return (
    <div>
      <Button
        type="primary"
        icon={<EditOutlined />}
        onClick={() => setOpen(true)}
        style={{ background: "orange", borderColor: "orange" }}
      >
        Edit
      </Button>

      <Drawer
        title="Edit Truck"
        placement="right"
        onClose={() => setOpen(false)}
        open={open}
      >
        {hasError && (
          <Alert
            type="error"
            message="An Error Occured while loading resources, Please check your connection and try again"
          />
        )}
        {loadingData && (
          <>
            Loading Resources <LoadingOutlined />{" "}
          </>
        )}
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={{
            ...truck,
            materials: defaultMaterials,
            serviceLocation: defaultZones,
          }}
        >
          {/* <Form.Item
            rules={[{ required: true, message: "First Name cannot be blank" }]}
            name="name"
            label="Truck Name"
          >
            <Input disabled={loadingData || hasError} />
          </Form.Item> */}
          <Row gutter={10}>
            <Col md={12} xs={24}>
              <Form.Item
                name="brand"
                label="Brand"
                rules={[{ required: true }]}
              >
                <Select disabled={loadingData || hasError}>
                  {brands?.map((brand) => (
                    <Option value={brand.brandName} key={brand.id}>
                      {brand.brandName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                name="materials"
                label="Materials"
                rules={[{ required: true }]}
                initialValue={defaultMaterials}
              >
                <Select
                  defaultValue={defaultMaterials}
                  disabled={loadingData || hasError}
                  mode="multiple"
                >
                  {materials?.map((mat) => (
                    <Option value={mat.id + "&" + mat.name} key={mat.id}>
                      <span style={{ textTransform: "capitalize" }}>
                        {mat.name}{" "}
                      </span>
                      <br />
                      {/* <small>{zone.description}</small> */}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col md={12} xs={24}>
              <Form.Item
                name="truckProfileId"
                label="Truck Profile"
                rules={[{ required: true }]}
              >
                <Select disabled={loadingData || hasError}>
                  {truckProfile &&
                    truckProfile?.map((profile: TruckCategoryProfile) => (
                      <Option value={profile.id} key={profile.id}>
                        {profile.type}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                initialValue={tonnage}
                rules={[
                  {
                    required: true,
                    message: "Tonnage cannot be blank",
                  },
                ]}
                name="tonnage"
                label="Tonnage"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col md={12} xs={24}>
              <Form.Item
                rules={[{ required: true, message: "Model cannot be blank" }]}
                name="model"
                label="Model"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Truck age cannot be blank",
                  },
                ]}
                name="age"
                label="Truck Age"
              >
                <Input type="number" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col md={8} xs={24}>
              <Form.Item
                rules={[
                  { required: true, message: "Head color cannot be blank" },
                ]}
                name="headColor"
                label="Head Color"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                rules={[
                  { required: true, message: "Bucket Color cannot be blank" },
                ]}
                name="bucketColor"
                label="Bucket Color"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Plate Number cannot be blank",
                  },
                ]}
                name="plateNumber"
                label="Plate Number "
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="picture"
            label="Upload Truck Image"
            // rules={[{ required: true, message: "Truck Image cannot be blank" }]}
            valuePropName="file"
            getValueFromEvent={(e) => {
              const file = e.fileList[0];
              if (file?.status === "done") {
                return file.response;
              }
            }}
          >
            <Upload
              customRequest={uploadToS3}
              listType="picture"
              multiple={false}
              maxCount={1}
              accept="image/*"
            >
              <Avatar src={truck.picture} />
              <Button icon={<ArrowUpOutlined />}>Upload Truck Image</Button>
              <br />
              <small>
                Image should not be greater than <b>20MB</b>
              </small>
            </Upload>
          </Form.Item>
          <Form.Item
            name="serviceLocation"
            label="Zones"
            rules={[{ required: true }]}
          >
            <Select
              disabled={loadingData || hasError}
              mode="multiple"
              defaultValue={defaultZones}
            >
              {zones?.map((zone: ZonesType) => {
                return (
                  <Option
                    value={`${zone.id}&${zone.name}&${zone.description}`}
                    key={zone.id}
                  >
                    {zone.name}
                    <br />
                    <small>{zone.description}</small>
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              loading={isSubmitting}
              htmlType="submit"
              block
              type="primary"
              size="large"
              disabled={loadingData || hasError}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
}
