import React, { useEffect, useState } from "react";
import { Button, Drawer, Form, message, Select } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import axiosConfig from "../util/axiosConfig";
import { OrdersType } from "../shared/interfaces/requests.interface";
import { TrucksType } from "../shared/interfaces/trucks.interface";
import requestErrorHandler from "../util/requestErrorHandler";

const OrderReasign = ({
  request,
  getRequest,
}: {
  request: OrdersType;
  getRequest: any;
}) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const [trucks, setTrucks] = useState<TrucksType[]>([]);

  async function handleSubmit() {
    setIsLoading(true);
    await axiosConfig
      .post(`/order/re-assign-order`, {
        orderRequestId: request.id,
        ...form.getFieldsValue(),
      })
      .then((response: any) => {
        message.success("Request Reassigned Successfully");
        form.resetFields();
        getRequest();
        setOpen(false);
        setIsLoading(false);
      })
      .catch((error) => {
        requestErrorHandler(error);
        setIsLoading(false);
      });
  }

  async function getTrucks() {
    setIsLoading(true);
    await axiosConfig
      .get("customer-trucks/get-all")
      .then((response: any) => {
        setTrucks(response.data.data);
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getTrucks();
  }, []);

  return (
    <>
      <Button
        type="primary"
        icon={<ReloadOutlined />}
        onClick={() => setOpen(true)}
      >
        Reasign
      </Button>
      <Drawer
        title="Reasign Request"
        placement="right"
        onClose={() => setOpen(false)}
        open={open}
      >
        <Form
          className="w-100"
          onFinish={handleSubmit}
          form={form}
          layout="vertical"
        >
          <Form.Item
            name="customerTruckId"
            label="Select Truck"
            rules={[{ required: true, message: "Select a truck" }]}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Select"
              options={trucks}
              fieldNames={{ label: "name", value: "id" }}
            />
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            block
            size="large"
            loading={isLoading}
            className="bg-[#096DD9]"
          >
            Save
          </Button>
        </Form>
      </Drawer>
    </>
  );
};

export default OrderReasign;
