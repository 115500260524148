import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { User } from "../../shared/interfaces/user.interface"

type AuthState = {
  token: string | null
  user: null | User
}

const initialState: AuthState = {
  user: null,
  token: null,
}

const slice = createSlice({
  initialState,
  name: "auth",
  reducers: {
    addToken(state, action: PayloadAction<string>) {
      state.token = action.payload
    },
    signIn(state, action: PayloadAction<User>) {
      state.user = action.payload
    },
    signOut(state) {
      state.token = null
      state.user = null
    },
  },
})

export default slice.reducer
export const { addToken, signIn, signOut } = slice.actions
