import React, { useEffect, useState } from "react";
import {
  Button,
  Drawer,
  Form,
  Input,
  InputNumber,
  message,
  Select,
  Switch,
} from "antd";
import axiosConfig from "../../../util/axiosConfig";
import TextArea from "antd/es/input/TextArea";
import { RenteesType } from "../../../shared/interfaces/truckowners.interface";
import requestErrorHandler from "../../../util/requestErrorHandler";

const AddCommission = ({ getCommissions }: any) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [commissionRate, setCommissionRate] = useState(false);
  const [processingFee, setProcessingFee] = useState(false);
  const [isGeneral, setIsGeneral] = useState(true);
  const [truckOwners, setTruckOwners] = useState<RenteesType[]>([]);
  const [form] = Form.useForm();

  async function handleSubmit() {
    let truckOwners = form.getFieldValue("truckOwners");
    let ids = truckOwners.map((truckOwnerId: string) => ({
      truckOwnerId: truckOwnerId,
    }));
    try {
      await axiosConfig
        .post("/commission/create", {
          ...form.getFieldsValue(),
          truckOwners: ids,
        })
        .then((response: any) => {
          message.success("Commission added successfully");
          form.resetFields();
          setOpen(false);
          setIsGeneral(true);
          setProcessingFee(false);
          setCommissionRate(false);
          getCommissions();
        })
        .catch((error) => {
          requestErrorHandler(error);
        });
    } catch (error: any) {
      requestErrorHandler(error);
    }
  }

  async function getRentees() {
    setIsLoading(true);
    await axiosConfig
      .get("truck-owners/get-all")
      .then((response: any) => {
        setTruckOwners(response.data.truckOwners);
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getRentees();
    form.validateFields(["processingFee", "commissionRate"]);
  }, [processingFee, commissionRate, form]);

  return (
    <>
      <Button
        type="primary"
        className="bg-[#096DD9]"
        onClick={() => setOpen(true)}
      >
        Add Commission
      </Button>
      <Drawer
        title="Add Commission"
        placement="right"
        onClose={() => {
          setOpen(false);
          form.resetFields();
        }}
        open={open}
      >
        <Form
          className="w-100"
          onFinish={handleSubmit}
          form={form}
          layout="vertical"
          initialValues={{ isGeneral: true }}
        >
          <Form.Item
            name="commissionName"
            label="Commission name"
            rules={[{ required: true, message: "Enter a valid name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true }]}
          >
            <TextArea />
          </Form.Item>
          <Form.Item
            name="commissionRate"
            label="Commission Rate"
            rules={[
              { required: commissionRate, message: "Enter a commission rate" },
              { type: "number" },
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              onChange={(value: any) => {
                if (value > 0) {
                  setCommissionRate(true);
                  setProcessingFee(false);
                } else {
                  setProcessingFee(false);
                  setCommissionRate(false);
                }
              }}
              disabled={processingFee}
            />
          </Form.Item>
          <Form.Item
            name="processingFee"
            label="Processing fees"
            rules={[
              { required: processingFee, message: "Enter a processing fees" },
              { type: "number" },
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              onChange={(value: any) => {
                if (value > 0) {
                  setProcessingFee(true);
                  setCommissionRate(false);
                } else {
                  setProcessingFee(false);
                  setCommissionRate(false);
                }
              }}
              disabled={commissionRate}
            />
          </Form.Item>
          <Form.Item
            name="isGeneral"
            label="General"
            rules={[{ required: true }]}
          >
            <Switch
              defaultChecked
              onChange={(value: boolean) => {
                setIsGeneral(value);
                value && form.setFieldValue("truckOwners", []);
              }}
            />
          </Form.Item>
          {!isGeneral ? (
            <Form.Item name="truckOwners" label="Truck owners">
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                placeholder="Select"
                options={truckOwners}
                fieldNames={{ label: "businessName", value: "id" }}
              />
              {/* {truckOwners.map((truckOwner, index) => (
                <Option value={truckOwner.id} label={truckOwner.businessName}>
                  <Space>
                    {/* <span role="img" aria-label="China">
                      🇨🇳
                    </span>
                    {truckOwner.businessName}
                  </Space>
                </Option>
              ))} 
            </Select>
              */}
            </Form.Item>
          ) : null}
          <Button
            type="primary"
            htmlType="submit"
            block
            size="large"
            loading={isLoading}
            className="bg-[#096DD9]"
          >
            Save
          </Button>
        </Form>
      </Drawer>
    </>
  );
};

export default AddCommission;
