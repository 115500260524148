import React, { useEffect, useState } from "react";
import { Breadcrumb, Skeleton, Table } from "antd";
import type { ColumnsType } from "antd/es/table";

import axiosConfig from "../../../util/axiosConfig";
import { Link } from "react-router-dom";

const Enquiries = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [page] = React.useState(1);
  const [paginationSize] = useState(10);
  const [renters, setRenters] = useState([]);

  const getEnquiries = async () => {
    setIsLoading(true);
    await axiosConfig
      .get("admin/get-contact-reports")
      .then((response: any) => {
        setRenters(response.data.contactReports);
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  interface IEnquiry {
    id: string;
    name: string;
    email: string;
    phoneNumber: string;
    report: string;
    createdAt: Date;
    updatedAt: Date;
  }

  const columns: ColumnsType<IEnquiry> = [
    {
      title: "#",
      dataIndex: "id",
      render: (_, record, index) => (page - 1) * paginationSize + index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, record, index) => (
        <Link
          to={`/admin/enquiries/${record.id}?name=${record.name}&phoneNumber=${record.phoneNumber}&email=${record.email}&createdAt=${record.createdAt}&id=${record.id}&updatedAt=${record.updatedAt}&report=${record.report}`}
        >
          {_}
        </Link>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Date submitted",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, record) => {
        let date = new Date(_);
        let formattedDate = date.toLocaleDateString("en-NG");
        return formattedDate;
      },
    },
  ];

  useEffect(() => {
    getEnquiries();
  }, []);

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="my-8">
          <h2 className="text-lg font-semibold">Renters</h2>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={"/admin"}>Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Enquiries</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div>{/* <AddRentee getRenters={getRenters} /> */}</div>
      </div>
      <div className="site-layout-background w-full overflow-auto bg-white">
        {isLoading ? (
          <Skeleton active paragraph={{ rows: 8 }} />
        ) : (
          <Table columns={columns} dataSource={renters} rowKey="id" />
        )}
      </div>
    </div>
  );
};

export default Enquiries;
