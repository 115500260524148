import React, { useEffect, useState } from "react";
import {
  Avatar,
  Breadcrumb,
  Button,
  message,
  Popconfirm,
  Skeleton,
  Space,
  Table,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { DeleteOutlined } from "@ant-design/icons";
import AddCategory from "./AddCategory";
import EditCategory from "./EditCategory";
import axiosConfig from "../../../util/axiosConfig";
import { Link } from "react-router-dom";
import requestErrorHandler from "../../../util/requestErrorHandler";

interface CategoriesType {
  id: string;
  truckName: string;
  truckImage: string;
}

const Categories = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [page] = React.useState(1);
  const [paginationSize] = useState(10);
  const [categories, setCategories] = useState([]);

  const columns: ColumnsType<CategoriesType> = [
    {
      title: "#",
      dataIndex: "truckName",
      render: (_, record, index) => (page - 1) * paginationSize + index + 1,
    },
    {
      title: "Truck Image",
      dataIndex: "truckImage",
      key: "truckImage",
      render: (_, record, index) => (
        <Link to={"/admin/categories/" + record.id}>
          <Avatar shape="square" size={50} src={record.truckImage} />
        </Link>
      ),
    },
    {
      title: "Truck Name",
      dataIndex: "truckName",
      key: "truckName",
      render: (_, record, index) => (
        <Link to={"/admin/categories/" + record.id}>{record.truckName}</Link>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <EditCategory category={record} getCategories={getCategories} />
          <Popconfirm
            title={`Are you sure you want to delete ${record.truckName} category?`}
            onConfirm={() => {
              handleDelete(record.id);
            }}
            okText="Delete"
            cancelText="Cancel"
          >
            <Button type="primary" danger>
              <DeleteOutlined />
              <span className="">Delete</span>
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  async function getCategories() {
    setIsLoading(true);
    await axiosConfig
      .get("/truck-category/getall")
      .then((response: any) => {
        setCategories(response.data.trucks);
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  async function handleDelete(id: any) {
    setIsLoading(true);
    await axiosConfig
      .delete(`/truck-category/delete?truckId=${id}`)
      .then((response: any) => {
        message.success("Category deleted successfully");
        getCategories();
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="my-8">
          <h2 className="text-lg font-semibold">Categories</h2>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={"/admin"}>Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Categories</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div>
          <AddCategory getCategories={getCategories} />
        </div>
      </div>
      <div className="site-layout-background w-full overflow-auto bg-white">
        {isLoading ? (
          <Skeleton active paragraph={{ rows: 8 }} />
        ) : (
          <Table columns={columns} dataSource={categories} rowKey="id" />
        )}
      </div>
    </div>
  );
};

export default Categories;
