export interface CommissionsType {
  id: string;
  description: string;
  processingFee: number;
  commissionName: string;
  commissionRate: number;
  isGeneral: boolean;
  truckOwners: any[];
}

export const CommissionsDefault = {
  id: "",
  description: "",
  processingFee: 0,
  commissionName: "",
  commissionRate: 0,
  isGeneral: false,
  truckOwners: [],
};
