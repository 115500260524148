export interface TrucksType {
  id: string;
  name: string;
  tonnage: string;
  truckProfileId: string;
  brand: string;
  model: string;
  age: string;
  headColor: string;
  bucketColor: string;
  plateNumber: string;
  picture: string;
  serviceLocation: any[];
  truckOwnerId: string;
  status: string;
  isAvailable: string;
  nextJobDate: string;
  jobState: boolean;
  materials: any[];
  driverName: string;
  driverPhone: string;
  driverPhoto: string;
  createdAt: string;
  updatedAt: string;
  truckOwner?: {
    id: string;
    businessName: string;
    businessLogo: string;
    businessAddress: string;
    state: string;
    userName: string;
    adminFirstName: string;
    adminLastName: string;
    adminEmail: string;
    adminPassword: string;
    contactNumber: string;
    status: string;
    bankAccName: string;
    bankAccNumber: string;
    amountDue: string;
    createdAt: string;
    updatedAt: string;
  };
  deviceObjectId: number;
  deviceUniqueId: string;
}

export const DefaultTrucks = {
  id: "",
  name: "",
  tonnage: "",
  truckProfileId: "",
  brand: "",
  model: "",
  age: "",
  headColor: "",
  bucketColor: "",
  plateNumber: "",
  picture: "",
  serviceLocation: [],
  truckOwnerId: "",
  status: "",
  isAvailable: "",
  nextJobDate: "",
  jobState: false,
  materials: [],
  driverName: "",
  driverPhone: "",
  driverPhoto: "",
  createdAt: "",
  updatedAt: "",
  deviceObjectId: 0,
  deviceUniqueId: "",
};
