import { Button } from "antd";
import useAuth from "../hooks/useAuth";
import { LogoutOutlined } from "@ant-design/icons";

function UserProfile() {
  const { logOut } = useAuth();

    return (
      <div className="flex items-center justify-end p-4">
        <Button onClick={() => logOut()}>
          <LogoutOutlined />
        </Button>
      </div>
    );
}

export default UserProfile;