import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Dropdown,
  Menu,
  Skeleton,
  Space,
  Table,
  message,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { DownOutlined } from "@ant-design/icons";
import axiosConfig from "../../../util/axiosConfig";
import { Link } from "react-router-dom";
import { RentersType } from "../../../shared/interfaces/renters.interface";
import requestErrorHandler from "../../../util/requestErrorHandler";

const Renters = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [page] = React.useState(1);
  const [paginationSize] = useState(10);
  const [renters, setRenters] = useState([]);

  async function suspendRenter(renterId: string, status: string) {
    setIsLoading(true);
    await axiosConfig
      .put(`/renters/suspend-account?renterId=${renterId}&status=${status}`)
      .then((response: any) => {
        message.success(
          `Renter ${
            status === "suspended" ? "suspended" : "activated"
          } successfully`
        );
        // setOpen(false);
        getRenters();
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const columns: ColumnsType<RentersType> = [
    {
      title: "#",
      dataIndex: "id",
      render: (_, record, index) => (page - 1) * paginationSize + index + 1,
    },
    {
      title: "Name",
      dataIndex: "lastName",
      key: "lastName",
      render: (_, record, index) => (
        <Link to={`/admin/renters/${record.id}`}>
          {record.firstName + " " + record.lastName}
        </Link>
      ),
    },
    {
      title: "Address",
      dataIndex: "businessAddress",
      key: "businessAddress",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Dropdown
            trigger={["click"]}
            overlay={
              <Menu>
                {record.status === "active" ? (
                  <Menu.Item
                    key="0"
                    onClick={() => {
                      suspendRenter(record.id, "suspended");
                    }}
                  >
                    Suspend
                  </Menu.Item>
                ) : (
                  <></>
                )}
                {record.status === "suspended" ? (
                  <Menu.Item
                    key="1"
                    onClick={() => {
                      suspendRenter(record.id, "active");
                    }}
                  >
                    Enable
                  </Menu.Item>
                ) : (
                  <></>
                )}
              </Menu>
            }
          >
            <Space className="cursor-pointer">
              More
              <DownOutlined />
            </Space>
          </Dropdown>
        </Space>
      ),
    },
  ];

  async function getRenters() {
    setIsLoading(true);
    await axiosConfig
      .get("renters/get-all")
      .then((response: any) => {
        setRenters(response.data.renters);
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getRenters();
  }, []);

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="my-8">
          <h2 className="text-lg font-semibold">Renters</h2>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={"/admin"}>Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Renters</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div>{/* <AddRentee getRenters={getRenters} /> */}</div>
      </div>
      <div className="site-layout-background w-full overflow-auto bg-white">
        {isLoading ? (
          <Skeleton active paragraph={{ rows: 8 }} />
        ) : (
          <Table columns={columns} dataSource={renters} rowKey="id" />
        )}
      </div>
    </div>
  );
};

export default Renters;
