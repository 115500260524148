function getFullDomain(url: string) {
  try {
    // eslint-ignore
    const match = url.match(/^(https?:\/\/[^\/:]+)/i);
    return match ? match[1] : null;
  } catch (error) {
    return null;
  }
}

export default getFullDomain;
