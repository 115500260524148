import React, { useEffect, useState } from "react";
import { Avatar, Breadcrumb, Dropdown, MenuProps, Skeleton, Space } from "antd";
import axiosConfig from "../../../util/axiosConfig";
import { Link, useParams } from "react-router-dom";
import Table, { ColumnsType } from "antd/es/table";
import { useCallback } from "react";
import { OrdersType } from "../../../shared/interfaces/requests.interface";
import { RenteesType } from "../../../shared/interfaces/truckowners.interface";
import { TrucksType } from "../../../shared/interfaces/trucks.interface";
import { DownOutlined } from "@ant-design/icons";
import requestErrorHandler from "../../../util/requestErrorHandler";

const ShowRentees = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [page] = useState(1);
  const [paginationSize] = useState(10);
  const { truckOwnerId } = useParams();
  const [truckOwner, setTruckOwner] = useState<RenteesType>();
  const [transactions, setTransactions] = useState<OrdersType[]>();
  const [trucks, setTrucks] = useState([]);
  const [status, setStatus] = useState<string>("pending");

  const transactionColumns: ColumnsType<OrdersType> = [
    {
      title: "#",
      dataIndex: "id",
      render: (_, record, index) => (page - 1) * paginationSize + index + 1,
    },
    {
      title: "Rental ID",
      dataIndex: "rentalId",
      key: "rentalId",
      render: (_, record, index) => (
        <Link to={"/admin/requests/" + record.id}>
          {record.rentalId ?? record.orderId}
        </Link>
      ),
    },
    {
      title: "Rental Status",
      dataIndex: "orderStatus",
      key: "orderStatus",
      render: (_, record) => (
        <div className="capitalize">{record.orderStatus}</div>
      ),
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      render: (_, record) => (
        <div className="capitalize">
          {record.paymentStatus ? "Paid" : "Pending"}
        </div>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (_, record) => (
        <div>
          {Number(record.price).toLocaleString("en-NG", {
            style: "currency",
            currency: "NGN",
          })}
        </div>
      ),
    },
  ];

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
            setStatus("pending");
            getTruckOwnersOrderTransactions("pending");
          }}
        >
          Pending
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
            setStatus("processing");
            getTruckOwnersOrderTransactions("processing");
          }}
        >
          Processing
        </a>
      ),
    },
    {
      key: "3",
      label: (
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
            setStatus("completed");
            getTruckOwnersOrderTransactions("completed");
          }}
        >
          Completed
        </a>
      ),
    },
    {
      key: "4",
      label: (
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
            setStatus("cancelled");
            getTruckOwnersOrderTransactions("cancelled");
          }}
        >
          Cancelled
        </a>
      ),
    },
  ];

  const columns: ColumnsType<TrucksType> = [
    {
      title: "#",
      dataIndex: "id",
      render: (_, record, index) => (page - 1) * paginationSize + index + 1,
    },
    {
      title: "Truck Name",
      dataIndex: "name",
      key: "name",
      render: (_, record, index) => (
        <Link to={"/admin/trucks/" + record.id}>{record.name}</Link>
      ),
    },
    {
      title: "Tonnage",
      dataIndex: "tonnage",
      key: "tonnage",
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "Age",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "Head Color",
      dataIndex: "headColor",
      key: "headColor",
    },
    {
      title: "Bucket Color",
      dataIndex: "bucketColor",
      key: "bucketColor",
    },
    {
      title: "Plate Number",
      dataIndex: "plateNumber",
      key: "plateNumber",
    },
    {
      title: "Picture",
      dataIndex: "picture",
      key: "picture",
      render: (_, record, index) => (
        <Avatar shape="square" size={50} src={record.picture} />
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Available",
      dataIndex: "isAvailable",
      key: "isAvailable",
      render: (_, record, index) => (
        <div>{record.isAvailable ? "Yes" : "No"}</div>
      ),
    },
  ];

  const getTruckOwner = useCallback(async () => {
    setIsLoading(true);
    await axiosConfig
      .get("truck-owners/get-one?truckOwnerId=" + truckOwnerId)
      .then((response: any) => {
        setTruckOwner(response.data.truckOwner);
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [truckOwnerId]);

  const getTruckOwnersOrderTransactions = useCallback(
    async (status: string) => {
      setIsLoading(true);
      await axiosConfig
        .get(
          `order/truck-owner-orders?truckOwnerId=${truckOwnerId}&paymentStatus=false&orderStatus=${status}`
        )
        .then((response: any) => {
          setTransactions(response.data.orderRequests);
        })
        .catch((error) => {
          requestErrorHandler(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [truckOwnerId]
  );

  const getTrucks = useCallback(async () => {
    setIsLoading(true);
    await axiosConfig
      .get(
        "customer-trucks/all-approved-by-truck-owner?truckOwnerId=" +
          truckOwnerId
      )
      .then((response: any) => {
        setTrucks(response.data.data);
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [truckOwnerId]);

  useEffect(() => {
    getTruckOwner();
    getTruckOwnersOrderTransactions(status);
    getTrucks();
  }, [getTruckOwner, getTruckOwnersOrderTransactions, getTrucks, status]);

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="my-8">
          <h2 className="text-lg font-semibold">Truck Owner</h2>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={"/admin"}>Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={"/admin/rentees"}>Truck Owners</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Truck Owner</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        {/* <div>{formatToNaira(truckOwner?.price ?? 0)}</div> */}
      </div>
      <div className="site-layout-background w-full overflow-auto">
        {isLoading ? (
          <Skeleton active paragraph={{ rows: 8 }} />
        ) : (
          <div>
            <div className="flex justify-between">
              <h4 className="text-lg font-semibold text-gray-600">
                Truck Owner Information
              </h4>
            </div>
            <div className="flex items-center bg-white p-8 rounded-md">
              <table className="w-full">
                <tbody>
                  <tr className="w-full">
                    <td className="w-1/2 py-3">
                      <strong>Full Name: </strong>
                    </td>
                    <td className="w-1/2">
                      {truckOwner?.adminFirstName +
                        " " +
                        truckOwner?.adminLastName}
                    </td>
                  </tr>
                  <tr className="w-full">
                    <td className="w-1/2 py-3">
                      <strong>Email: </strong>
                    </td>
                    <td className="w-1/2">{truckOwner?.adminEmail}</td>
                  </tr>
                  <tr className="w-full">
                    <td className="w-1/2 py-3">
                      <strong>Business name: </strong>
                    </td>
                    <td className="w-1/2">{truckOwner?.businessName}</td>
                  </tr>
                  <tr className="w-full">
                    <td className="w-1/2 py-3">
                      <strong>Contact number: </strong>
                    </td>
                    <td className="w-1/2">{truckOwner?.contactNumber}</td>
                  </tr>
                  <tr className="w-full">
                    <td className="w-1/2 py-3">
                      <strong>Address: </strong>
                    </td>
                    <td className="w-1/2">{truckOwner?.businessAddress}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <h4 className="text-lg font-semibold text-gray-600">
              Truck Owner Order Transactions
            </h4>
            <Dropdown.Button
              menu={{ items }}
              icon={<DownOutlined />}
              className="mb-2"
            >
              <Space className="capitalize">{status}</Space>
            </Dropdown.Button>

            <Table
              columns={transactionColumns}
              dataSource={transactions}
              rowKey="id"
            />

            <h4 className="text-lg font-semibold text-gray-600 gap-2">
              Truck Owner Trucks
            </h4>
            <Table columns={columns} dataSource={trucks} rowKey="id" />
          </div>
        )}
      </div>
    </div>
  );
};

export default ShowRentees;
