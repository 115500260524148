import React, { useState } from "react";
import { Button, Drawer, Form, Input, message, Upload } from "antd";
import { ArrowUpOutlined } from "@ant-design/icons";
import axiosConfig from "../../../util/axiosConfig";
import uploadToS3 from "../../../util/uploadToS3";
import requestErrorHandler from "../../../util/requestErrorHandler";

const AddCategory = ({ getCategories }: any) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  async function handleSubmit() {
    setIsLoading(true);
    await axiosConfig
      .post("/truck-category/create", form.getFieldsValue())
      .then((response: any) => {
        message.success("Category added successfully");
        form.resetFields();
        getCategories();
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setOpen(false);
        setIsLoading(false);
      });
  }

  return (
    <>
      <Button
        type="primary"
        className="bg-[#096DD9]"
        onClick={() => setOpen(true)}
      >
        Add Category
      </Button>
      <Drawer
        title="Add Truck Category"
        placement="right"
        onClose={() => setOpen(false)}
        open={open}
      >
        <Form
          className="w-100"
          onFinish={handleSubmit}
          form={form}
          layout="vertical"
        >
          <Form.Item
            name="truckName"
            label="Category name"
            rules={[{ required: true, message: "Enter a valid name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="truckImage"
            label="Upload Category Image"
            valuePropName="file"
            getValueFromEvent={(e) => {
              const file = e.fileList[0];
              if (file?.status === "done") {
                return file.response;
              }
            }}
            className="w-full"
            rules={[{ required: true, message: "Upload an image" }]}
          >
            <Upload
              name="image"
              customRequest={uploadToS3}
              listType="picture"
              multiple={false}
              maxCount={1}
              accept="image/*"
              className="w-full"
            >
              <Button
                icon={<ArrowUpOutlined />}
                className="w-full"
                style={{ width: "100%" }}
              >
                Click Here to upload an image
              </Button>
              <br />
              <small>
                Image should not be greater than <b>20MB</b>
              </small>
            </Upload>
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            block
            size="large"
            loading={isLoading}
            className="bg-[#096DD9]"
          >
            Save
          </Button>
        </Form>
      </Drawer>
    </>
  );
};

export default AddCategory;
