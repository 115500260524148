// @ts-ignore
import axios from "axios";

const API_URL = process.env.REACT_APP_MATPRO_API;

export default async function uploadToS3({
  file,
  onError,
  onSuccess,
  onProgress,
  filename,
}: any) {
  try {
    const formData: any = new FormData();

    // // Update the formData object
    formData.append("file", file!, filename);

    const { data } = await axios.post(`${API_URL}/upload`, formData);
    onSuccess?.(
      data.location,
      //@ts-ignore
      file
    );
    return data.location;
  } catch (error) {
    //@ts-ignore
    onError?.(error);
  }
}
