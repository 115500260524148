import React, { useState } from "react";
import {
  BuildOutlined,
  CarOutlined,
  GroupOutlined,
  HomeOutlined,
  MenuFoldOutlined,
  MessageOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { MenuProps, Layout, Menu } from "antd";
import { Link, Outlet } from "react-router-dom";
import Logo from "./Logo";
import UserProfile from "./UserProfile";

const { Header, Content, Footer, Sider } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    label,
    key,
    icon,
    children,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem(<Link to="/admin">Dashboard</Link>, "1", <HomeOutlined />),
  getItem(
    <Link to="/admin/requests">Requests</Link>,
    "12",
    <ShoppingCartOutlined />
  ),
  getItem(<Link to="/admin/zones">Zones</Link>, "2", <GroupOutlined />),
  getItem(
    <Link to="/admin/materials">Materials</Link>,
    "materials-1",
    <BuildOutlined />
  ),
  getItem("Trucks", "truck", <CarOutlined />, [
    getItem(<Link to="/admin/trucks">Trucks</Link>, "3"),
    getItem(<Link to="/admin/brands">Truck brands</Link>, "4"),
    getItem(<Link to="/admin/categories">Truck categories</Link>, "5"),
  ]),
  getItem("Users", "sub1", <TeamOutlined />, [
    getItem(<Link to="/admin/renters">Renters</Link>, "6"),
    getItem(<Link to="/admin/rentees">Truck Owners</Link>, "7"),
    getItem(<Link to="/admin/partners">Partners</Link>, "10"),
    getItem(<Link to="/admin/administrators">Admin users</Link>, "8"),
  ]),
  // getItem(<Link to="/admin/opex">Opex</Link>, "11", <DollarOutlined />),
  getItem("Settings", "sub2", <SettingOutlined />, [
    getItem(<Link to="/admin/commissions">Commission</Link>, "9"),
  ]),
  getItem(<Link to="/admin/enquiries">Enquiries</Link>, "11", <MessageOutlined />),

];

const AdminLayout = () => {
  const [collapsed, setCollapsed] = useState(false);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        theme="light"
        trigger
        breakpoint="lg"
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        style={{
          overflow: "auto",
          height: "100vh",
          position: "sticky",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <div className="flex items-center justify-center py-4">
          <Logo size={100} />
        </div>
        <div className="flex items-center justify-start p-4">
          <MenuFoldOutlined onClick={toggle} />
        </div>
        <Menu mode="inline" items={items} />
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={{
            backgroundColor: "white",
            padding: 0,
            position: "sticky",
            top: 0,
            zIndex: 1,
            width: "100%",
          }}
        >
          <UserProfile />
        </Header>
        <Content
          className="site-layout"
          style={{ margin: "24px 16px 0", overflow: "initial" }}
        >
          <div className="site-layout-background">
            <Outlet />
          </div>
        </Content>
        <Footer style={{ textAlign: "center" }}>
          GoDump {new Date().getFullYear()} All Right Reserved.
        </Footer>
      </Layout>
    </Layout>
  );
};

export default AdminLayout;
