import React, { useEffect, useState } from "react";
import { Breadcrumb, Dropdown, Menu, Skeleton, Space, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { DownOutlined } from "@ant-design/icons";
import axiosConfig from "../../../util/axiosConfig";
import AddPartner from "./AddPartner";
import { Link } from "react-router-dom";
import AddPayment from "./AddPayment";
import SetLimit from "./SetLimit";
import { formatToNaira } from "../../../util/money";
import { PartnersType } from "../../../shared/interfaces/partners.interface";
import requestErrorHandler from "../../../util/requestErrorHandler";

const Partners = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [page] = React.useState(1);
  const [paginationSize] = useState(10);
  const [partners, setPartners] = useState([]);

  const columns: ColumnsType<PartnersType> = [
    {
      title: "#",
      dataIndex: "id",
      render: (_, record, index) => (page - 1) * paginationSize + index + 1,
    },
    {
      title: "Business Name",
      dataIndex: "businessName",
      key: "businessName",
      render: (_, record, index) => (
        <Link to={"/admin/partners/" + record.id}>{record.businessName}</Link>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Business Address",
      dataIndex: "businessAddress",
      key: "businessAddress",
    },
    {
      title: "Amount Paid",
      dataIndex: "totalAmountPaid",
      key: "totalAmountPaid",
      render: (_, record) => <div>{formatToNaira(record.totalAmountPaid)}</div>,
    },
    {
      title: "Amount Owed",
      dataIndex: "totalAmountOwed",
      key: "totalAmountOwed",
      render: (_, record) => <div>{formatToNaira(record.totalAmountOwed)}</div>,
    },
    {
      title: "Limit",
      dataIndex: "limit",
      key: "limit",
      render: (_, record) => <div>{formatToNaira(record.limit)}</div>,
    },
    {
      title: "API Key",
      dataIndex: "apiKey",
      key: "apiKey",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Dropdown
            trigger={["click"]}
            overlay={
              <Menu>
                {record.apiKey && (
                  <Menu.Item
                    key="0"
                    onClick={() => {
                      revokeAPIKey(record.id);
                    }}
                  >
                    Revoke Key
                  </Menu.Item>
                )}
                <Menu.Item
                  key="3"
                  onClick={() => {
                    regenerateAPIKey(record.id);
                  }}
                >
                  Regenerate Key
                </Menu.Item>
                <Menu.Item key="4">
                  <AddPayment partner={record} getPartners={getPartners} />
                </Menu.Item>
                <Menu.Item key="5">
                  <SetLimit partner={record} getPartners={getPartners} />
                </Menu.Item>
              </Menu>
            }
          >
            <Space className="cursor-pointer">
              More
              <DownOutlined />
            </Space>
          </Dropdown>
        </Space>
      ),
    },
  ];

  async function getPartners() {
    setIsLoading(true);
    await axiosConfig
      .get("partner/get-all")
      .then((response: any) => {
        setPartners(response.data.data);
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  async function regenerateAPIKey(id: any) {
    setIsLoading(true);
    await axiosConfig
      .post("partner/generate-new-api-key", { partnerId: id })
      .then((response: any) => {
        getPartners();
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  async function revokeAPIKey(id: any) {
    setIsLoading(true);
    await axiosConfig
      .post("partner/revoke-api-key", { partnerId: id })
      .then((response: any) => {
        getPartners();
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getPartners();
  }, []);

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="my-8">
          <h2 className="text-lg font-semibold">Partners</h2>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={"/admin"}>Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Partners</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div>
          <AddPartner getPartners={getPartners} />
        </div>
      </div>
      <div className="site-layout-background w-full overflow-auto bg-white">
        {isLoading ? (
          <Skeleton active paragraph={{ rows: 8 }} />
        ) : (
          <Table columns={columns} dataSource={partners} rowKey="id" />
        )}
      </div>
    </div>
  );
};

export default Partners;
