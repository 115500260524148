import React, { useEffect, useState } from "react";
import {
  Avatar,
  Breadcrumb,
  Dropdown,
  Menu,
  message,
  Skeleton,
  Space,
  Table,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { DownOutlined } from "@ant-design/icons";
import axiosConfig from "../../../util/axiosConfig";
import RenteePasswordChange from "./RenteePasswordChange";
import { RenteesType } from "../../../shared/interfaces/truckowners.interface";
import { Link } from "react-router-dom";
import requestErrorHandler from "../../../util/requestErrorHandler";

const Rentees = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [page] = React.useState(1);
  const [paginationSize] = useState(10);
  const [administrators, setRentees] = useState([]);

  const columns: ColumnsType<RenteesType> = [
    {
      title: "#",
      dataIndex: "id",
      render: (_, record, index) => (page - 1) * paginationSize + index + 1,
    },
    {
      title: "Businees Name",
      dataIndex: "businessName",
      key: "businessName",
      render: (_, record, index) => (
        <Link to={`/admin/rentees/${record.id}`}>{record.businessName}</Link>
      ),
    },
    {
      title: "Business Logo",
      dataIndex: "businessLogo",
      key: "businessLogo",
      render: (_, record, index) => (
        <Avatar shape="square" size={50} src={record.businessLogo} />
      ),
    },
    {
      title: "Truck Owners",
      dataIndex: "adminFirstName",
      key: "adminFirstName",
      render: (_, record, index) => (
        <div>{record.adminFirstName + " " + record.adminLastName}</div>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "contactNumber",
      key: "contactNumber",
    },
    {
      title: "Email",
      dataIndex: "adminEmail",
      key: "adminEmail",
    },
    {
      title: "Address",
      dataIndex: "businessAddress",
      key: "businessAddress",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Dropdown
            trigger={["click"]}
            overlay={
              <Menu>
                {record.status !== "approved" && (
                  <Menu.Item
                    key="0"
                    onClick={() => {
                      handleApproval(record.id);
                    }}
                  >
                    Approve
                  </Menu.Item>
                )}
                {record.status !== "approved" && (
                  <Menu.Item
                    key="2"
                    onClick={() => {
                      handleRejection(record.id);
                    }}
                  >
                    Reject
                  </Menu.Item>
                )}
                <Menu.Item key="3">
                  <RenteePasswordChange
                    rentee={record}
                    getRentees={getRentees}
                  />
                </Menu.Item>
              </Menu>
            }
          >
            <Space className="cursor-pointer">
              More
              <DownOutlined />
            </Space>
          </Dropdown>
        </Space>
      ),
    },
  ];

  async function getRentees() {
    setIsLoading(true);
    await axiosConfig
      .get("truck-owners/get-all")
      .then((response: any) => {
        setRentees(response.data.truckOwners);
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  async function handleApproval(id: any) {
    await axiosConfig
      .post(`/truck-owners/approve?truckOwnerId=${id}`)
      .then((response: any) => {
        message.success("Truck Owner approved successfully");
        getRentees();
      })
      .catch((error) => {
        requestErrorHandler(error);
      });
  }

  async function handleRejection(id: any) {
    await axiosConfig
      .post(`/truck-owners/reject?truckOwnerId=${id}`)
      .then((response: any) => {
        message.success("Truck Owner rejected successfully");
        getRentees();
      })
      .catch((error) => {
        requestErrorHandler(error);
      });
  }

  useEffect(() => {
    getRentees();
  }, []);

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="my-8">
          <h2 className="text-lg font-semibold">Truck Owners</h2>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={"/admin"}>Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Trucker Owners</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div>{/* <AddRentee getRentees={getRentees} /> */}</div>
      </div>
      <div className="site-layout-background w-full overflow-auto bg-white">
        {isLoading ? (
          <Skeleton active paragraph={{ rows: 8 }} />
        ) : (
          <Table columns={columns} dataSource={administrators} rowKey="id" />
        )}
      </div>
    </div>
  );
};

export default Rentees;
