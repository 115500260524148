import { useEffect, useState } from "react";
import { Alert, Breadcrumb, Button, Skeleton, Spin } from "antd";
import axiosConfig from "../../../util/axiosConfig";
import {
  DefaultTrucks,
  TrucksType,
} from "../../../shared/interfaces/trucks.interface";
import { Link, useParams } from "react-router-dom";
import { useCallback } from "react";
import requestErrorHandler from "../../../util/requestErrorHandler";
import useQuery from "../../../hooks/useQuery";
import PageSpinner from "../../../components/PageSpinner";
import { socket } from "../../../util/socket";
import MapView from "../../../components/MapView";
import disabledMap from "../../../assets/disabled-map.png";

const Tracking = () => {
  const { truckId } = useParams();

  const deviceObjectId = useQuery().get("deviceObjectId");
  const currentTrip = useQuery().get("currentTrip");
  const numOfTrips = useQuery().get("numOfTrips");

  const [isLoading, setIsLoading] = useState(false);
  const [devicePosition, setDevicePosition] = useState<DevicePosition>();
  const [truck, setTruck] = useState<TrucksType>({ ...DefaultTrucks });

  const getTruck = useCallback(async () => {
    setIsLoading(true);
    await axiosConfig
      .get("customer-trucks/get-one?truckId=" + truckId)
      .then((response: any) => {
        setTruck(response.data.truck);
      })
      .then(async () => {
        return getTruckPosition();
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const getTruckPosition = async () => {
    const {
      data,
    }: {
      data: {
        message: string;
        position: { locationDetails: DevicePosition };
      };
    } = await axiosConfig.get(
      `eonsfleet/get-postion?deviceObjectId=${deviceObjectId}`
    );

    setDevicePosition(data?.position?.locationDetails);
    return data?.position?.locationDetails;
  };

  useEffect(() => {
    getTruck();
    socket.connect();

    socket.on("locationStatus", (data) => {
      if (
        data?.locationDetails?.DeviceObjectId === devicePosition?.DeviceObjectId
      ) {
        setDevicePosition(data?.locationDetails);
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const isWebview = !window.location.pathname.includes("admin");

  const showTruckDetails = (isWebview && devicePosition) || !isWebview;

  if (isLoading) {
    return <Skeleton active paragraph={{ rows: 4 }} />;
  }

  return (
    <div className="p-0 h-[100vh] bg-gray-100">
      {!isWebview && (
        <div className="flex items-center justify-between ">
          <div className="my-0">
            <h2 className="text-lg font-semibold">Live Tracking</h2>

            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={"/admin/trucks"}>Trucks</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/admin/trucks/${truckId}`}>{truck.brand}</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Tracking</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      )}
      <div className="site-layout-background w-full  overflow-auto">
        <div className="h-full">
          {showTruckDetails && (
            <div
              className={`w-full ${
                isWebview ? "h-[15vh]" : "h-full"
              }  flex border-2  bg-white  md:flex-nowrap justify-between gap-4 shadow-lg rounded-lg overflow-hidden`}
            >
              <div className="w-full md:w-1/6 bg-white p-2 rounded-md flex items-center">
                <div className="w-full flex justify-center item-center">
                  <img
                    src={truck.picture}
                    alt={truck.name}
                    className="w-[100%] h-[100%] object-cover rounded-md "
                  />
                </div>
              </div>
              <div className=" w-full flex-1 flex items-center bg-white p-8 rounded-md">
                <table className="w-full">
                  <tbody>
                    <tr className="w-full">
                      <td className="w-1/2 py-1">
                        <strong>Brand: </strong>
                      </td>
                      <td className="w-1/2">{truck?.brand}</td>
                    </tr>
                    <tr className="w-full">
                      <td className="w-1/2 py-1">
                        <strong>Model: </strong>
                      </td>
                      <td className="w-1/2">{truck?.model}</td>
                    </tr>
                    {!isWebview && (
                      <>
                        <tr className="w-full">
                          <td className="w-1/2 py-1">
                            <strong>Device Object Id: </strong>
                          </td>
                          <td className="w-1/2">{truck?.deviceObjectId}</td>
                        </tr>
                        <tr className="w-full">
                          <td className="w-1/2 py-1">
                            <strong>Device Unique Id: </strong>
                          </td>
                          <td className="w-1/2">{truck?.deviceUniqueId}</td>
                        </tr>
                      </>
                    )}
                    <tr className="w-full">
                      <td className="w-1/2 py-1">
                        <strong>Speed: </strong>
                      </td>
                      <td className="w-1/2"> {devicePosition?.Speed}Km/h </td>
                    </tr>
                    {isWebview && (
                      <tr className="w-full">
                        <td className="w-1/2 py-1">
                          <strong> Trips: </strong>
                        </td>
                        <td className="w-1/2">
                          {" "}
                          {currentTrip}/{numOfTrips}{" "}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          <div
            className={`mt-4 border-red-100 ${
              isWebview ? "h-[70vh]" : "h-[50vh]"
            } `}
          >
            {devicePosition?.Latitude && (
              <MapView devicePosition={devicePosition!} />
            )}
            {/* {devicePosition === undefined && <Spin />} */}
            {!devicePosition && (
              <>
                {!isWebview ? (
                  <>
                    <Alert
                      message={
                        <>
                          Unable to fetch device information <br />
                        </>
                      }
                    />
                    <br />
                    <small>
                      Please confirm that device<b> {deviceObjectId}</b> is
                      active{" "}
                    </small>
                  </>
                ) : (
                  <div className="h-[100vh] w-full fixed flex top-0 left-0 items-center justify-center border-2 ">
                    <div className="  p-5 rounded-md flex flex-col gap-5 w-[80%] m-auto text-center ">
                      <h3 className="opacity-50">
                        {" "}
                        Unable to get current Truck location
                      </h3>
                      <img
                        src={disabledMap}
                        alt=""
                        width="100%"
                        height="100%"
                      />
                      <Button
                        onClick={() => window.location.reload()}
                        className=" bg-[#000000]  text-[#EB5017] rounded-full shadow-lg"
                      >
                        Try again
                      </Button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tracking;
